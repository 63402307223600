.header-redblu{
  position:relative;
  z-index: 10;
  height:60px;
  box-shadow: rgb(33 35 38 / 20%) 0px 2px 10px -1px;
}

.header-redblu-logo img{
  padding:0 0.8em 0 0.8em;
  min-width: 160px;
}
.header-redblu-logo{
  border-right:1px solid #f2f2f2;
  padding-right:1em;
  margin-right:1em;
}

.header-redblu-nav button{
  height:60px;
  padding:0 0.8em 0 0.8em;
  text-transform: none;
  letter-spacing: 0px;
  border-top:4px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 0px;
  font-family: 'Poppins', sans-serif; 
  font-size: 0.85rem;
  color:#8A8A8A
}

.header-redblu-nav button.active{
  color:#4354FE;
  border-bottom-color: #4354FE;
}


.header-profile-nav .MuiListSubheader-root{
  font-size:12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal;
}
.header-profile-nav .MuiListItem-root{
  font-size:14px;
}
.header-profile-nav .MuiListItem-root svg{
  opacity:0.6;
}
.header-profile-nav .logout{
  font-weight: bold;
}
.header-redblu-profile .profile-button{
  background-color:rgba(84,61,208,0.1);
  border-radius: 6px;
  letter-spacing: 0px;
  padding:0.4em 0.8em;
  text-align: left;
  height: 60px;
}
.header-redblu-profile .profile-button span{
  color:rgb(84, 61, 208);
  font-weight:500;
  font-size:14px;
  text-transform: capitalize;
}
.header-redblu-profile .profile-button svg{
  color:rgb(84, 61, 208); 
}
.header-redblu-select .MuiAutocomplete-root{
  border-bottom: 3px solid transparent;
  border-radius: 0px;
  border-top: 4px solid transparent;
  color: rgb(84, 61, 208);
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  height: 60px;
  letter-spacing: 0px;
  min-width: 320px;
  padding: 0 0.8em 0 0.8em;
  text-transform: none;
}

.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.abs-center-50 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.profile-redblu{
    padding-top: 0.8rem;
    padding-right: 1rem;;
    border-right: 1px solid #f2f2f2;
}

.profile-redblu > .name{
  font-size: 0.9rem;
  color: #4354FE;
  font-weight: bold;
}
.profile-redblu > .titulo{
  font-size: 0.9rem;
  color: #434344;
  font-weight: normal;
}
.icon-profile{
  color: #4354FE !important ;
  font-weight: normal;
  margin-left: 0.5rem;

}