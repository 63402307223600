.today-appointments{
  display: block;
  padding: 20px;
  margin: 0px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 10%);
  font-family: 'Poppins', sans-serif; 
}
.today-appointments h2{
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif; 
  font-weight: 400;
}
.today-appointments h2 b{
  color:#4354FE;
  font-weight: 600;
}
.today-appointments h3,
.next-appointments h3{
  font-size: 1rem;
  font-family: 'Poppins', sans-serif; 
  font-weight: 400;
  color:black;
}
.today-appointments h4,
.next-appointments h4{
  font-size: 0.75rem;
  font-family: 'Poppins', sans-serif; 
  font-weight: 600;
  text-transform: uppercase;
  color:#999
}
.next-appointments{
  padding:20px;
}
.next-appointments h3 b{
  color:#4354FE;
  font-weight: 500;
}





.react-pagination-table {
    position: relative;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 0px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin: 10px 0px;
    border: 1px solid rgba(60, 20, 105, 0.021);
    border-radius: 3px;
}

.react-pagination-table__sub-title {
    float: right;
}

.react-pagination-table > h4.title,
.react-pagination-table > .sub-title {
   font-size: 20px;
    font-weight: bold;
    line-height: 26px;

}

.react-pagination-table > table >.table-header {
    background: #f1ecec4f;
}

.react-pagination-table > table > tbody {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px;
    border: 1px solid rgba(19, 17, 17, 0.021);
}

.pagination-status {
    display: inline-block;
    padding-left: 0;
}

.pagination-status__item {
    display: inline;
}

.pagination-status .pagination-status__btn {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    color: #4354FE;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #533dd034;
}

.pagination-status .pagination-status__btn:focus {
    outline: none;
}

.pagination-status .pagination-status__btn--active,
.pagination-status .pagination-status__btn:hover {
    color: #fff;
    background-color: #4354FE;
    border-color: #4354FE;
    cursor: pointer;
}

.pagination-status .pagination-status__btn--disable,
.pagination-status .pagination-status__btn--disable:hover {
    color: #d0d0d0;
    cursor: default;
    border-color: #d0d0d0;
    background-color: #fff;
}