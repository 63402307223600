.mensaje-inicial p{
  font-family: 'Poppins', sans-serif; 
  font-size:14px;
  font-weight: 400;
  margin-bottom: 0;
  color:black;
}

.mensaje-inicial ul{
  font-family: 'Poppins', sans-serif; 
  font-size:14px;
  font-weight: 400;
  margin-bottom: 0;
  color:black;
}

.mensaje-inicial li{
  padding-top:12px;  
}

.mensaje-inicial li::marker{
  color:#4354FE;
}