@charset "utf-8";

@font-face {
   font-family: "gothamblack";
   src: url("../fonts/gotham-black-webfont.woff2") format("woff2"),
      url("../fonts/gotham-black-webfont.woff") format("woff");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: "gothammedium";
   src: url("../fonts/gotham-medium-webfont.woff2") format("woff2"),
      url("../fonts/gotham-medium-webfont.woff") format("woff");
   font-weight: normal;
   font-style: normal;
}

/* 
	====================================

		GENERAL

	==================================== 
*/
html {
   scroll-behavior: smooth;
}

::-webkit-scrollbar {
   width: 0px;
   background: transparent;
   /* make scrollbar transparent */
}

::-webkit-scrollbar {
   width: 8px;
   /* Tamaño del scroll en vertical */
   height: 8px;
   /* Tamaño del scroll en horizontal */
   display: none;
   /* Ocultar scroll */
}

body {
   margin: 0;
   padding: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "Lato", "Arial", sans-serif;
}


body.ReactModal__Body--open,
body.no-scroll{
   overflow: hidden;
}


h1,
h2,
h3,
h4,
h5 {
   font-family: "Lato", "Arial", sans-serif;
}

h1 {
   font-size: 19px;
   font-weight: 800;
   letter-spacing: -0.3px;
   margin-bottom: 20px;
}

p,
input,
label {
   font-family: "Roboto", "Arial", sans-serif;
   font-size: 14px;
   line-height: 20px;
   color: #666;
}

.header-bar {
   height: 9%;
}

.body-90 {
   height: 91%;
   overflow: hidden;
   position: relative;
}

.body-90 div:first-child {
   height: 100%;
}

.fullh {
   height: 100%;
   position: relative;
   max-width: 600px;
   margin: auto;
}

.topmenu {
   height: 8%;
   top: 0;
   width: 100%;
}

.footermenu {
   height: 8%;
   position: fixed;
   bottom: 0;
   width: 100%;
}

.body-topmenu {
   height: 84%;
}

.height-1 {
   height: 10% !important;
}

.height-2 {
   height: 20% !important;
}

.height-3 {
   height: 30% !important;
}

.height-4 {
   height: 40% !important;
}

.height-5 {
   height: 50% !important;
}

.height-6 {
   height: 60% !important;
}

.height-7 {
   height: 70% !important;
}

.height-8 {
   height: 80% !important;
}

.height-9 {
   height: 90% !important;
}

.height-100 {
   height: 100% !important;
}

.height40 {
   height: 40vh;
}

.relative {
   position: relative;
}

/* 
	====================================

		LOGIN

	==================================== 
*/
.create-account a {
   color: #4354FE;
   font-weight: bold;
   display: inline-block;
}

.create-account a.forgot-password {
   font-weight: normal;
   color: #999;
   font-size: 14px;
   display: inline-block;
   margin-top: 8px;
}

/* 
	====================================

		INTRODUCCIÓN

	==================================== 
*/
.carousel .slide {
   background-color: white;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide img {
   padding: 0 30px;
   padding-top: 15px;
   max-height: 70%;
}

.carousel .slide .legend {
   background-color: inherit;
   color: black;
   opacity: 1;
   position: relative;
   bottom: inherit;
   left: inherit;
   margin: auto;
   font-size: 26px;
   padding: 0;
   letter-spacing: 0;
   color: #4354FE;
   margin-top: 30px;
   font-weight: 300;
}

.carousel .slide .legend strong {
   font-weight: bold;
}

.carousel .control-dots {
   padding: 0;
   bottom: 15px;
}

.carousel .control-dots .dot {
   box-shadow: inherit;
   background: #e2e2e2;
   width: 11px;
   height: 11px;
   opacity: 1;
   margin: 0 12px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
   background-color: #4354FE;
}

.header-redgesam .logo-corporativo img {
   max-height: 80px;
   width:auto;
}

.header-redgesam {
   box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
   margin: 0px 8px 0px 8px;
   width: auto;
   border-bottom-right-radius: 6px;
   border-bottom-left-radius: 6px;
   padding-top: 6px;
}

/* 
	====================================

		TÉRMINOS Y CONDICIONES

	==================================== 
*/
.terms {
   border: 1px solid #f2f2f2;
   padding: 10px 15px;
   border-radius: 3px;
   text-align: justify;
   overflow: auto;
   max-height: 50vh;
}

.terms::-webkit-scrollbar-track {
   background-color: #e2e2e2;
   border-radius: 3px;
}

.terms::-webkit-scrollbar {
   width: 6px;
   background-color: #f5f5f5;
   display: block;
}

.terms::-webkit-scrollbar-thumb {
   border-radius: 3px;
   background-color: #4354FE;
}

.terms .red {
   color: red;
}

.terms p {
   font-size: 12px;
   text-align: justify;
}

.terms h1 {
   text-align: center;
   font-size: 14px;
}

.terms h2 {
   font-size: 13px;
   font-weight: bold;
}

.terms ol {
   font-size: 14px;
   font-weight: 500;
   color: #666;
}

.terms ol li {
   margin-bottom: 15px;
   font-size: 12px;
}

/* 
	====================================

		CREACIÓN DE CUENTA

	==================================== 
*/
.movilapp-container {
   max-width: 440px;
   border: 1px solid #f2f2f2;
}

.header-short {
   padding: 8px 20px;
   -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
   z-index: 2;
   position: relative;
}

.header-short-black {
   background-color: black;
}

.header-short-black .stepped-title span {
   color: white;
}

.stepped-nav {
   display: flex;
   height: 30px;
}

.steped-component {
   position: relative;
}

.steped-component .backbutton a {
   display: inline-block;
   padding-top: 1px;
}

.backbutton-white svg {
   fill: white;
   stroke: white;
}

.steped-component .backbutton {
   width: 10%;
   text-align: center;
}

.steped-component .stepped-progress {
   width: 80%;
   margin: 0 30px;
   display: flex;
   height: 6px;
   padding-top: 13px;
   box-sizing: content-box;
}

.steped-component .stepped-number {
   width: 10%;
   font-family: "Roboto", sans-serif;
   font-size: 14px;
   color: #acacac;
   display: block;
   line-height: 30px;
}

.steped-component .stepped-item {
   display: inline-block;
   content: "";
   background-color: rgb(0, 0, 0, 0.1);
}

.steped-component .stepped-item {
   transition: width 1s;
}

.steped-component .stepped-item:first-child {
   border-top-left-radius: 3px;
   border-bottom-left-radius: 3px;
   box-sizing: inherit;
}

.steped-component .stepped-item:last-child {
   border-top-right-radius: 3px;
   border-bottom-right-radius: 3px;
}

.steped-component .stepped-completed {
   background-color: #4354FE;
}

.steped-component .stepped-fullwidth {
   width: 100%;
   margin: 0px;
   padding-top: 0px;
   position: absolute;
   bottom: -1px;
   z-index: 3;
}

.steped-component .stepped-fullwidth .stepped-item:first-child {
   border-radius: 0px;
}

.stepped-title {
   display: inline-block;
   width: 78%;
   text-align: center;
}

.stepped-title span {
   font-weight: 500;
   color: black;
   font-size: 16px;
   display: block;
   margin-top: 3px;
   letter-spacing: 0;
}

.middle-center-content {
   padding: 20px;
}

input {
   background-color: #f1f2f3;
   border: 0;
   display: block;
   height: 40px;
   width: 100%;
   padding: 0 15px;
   box-sizing: border-box;
   letter-spacing: 1px;
   border: 1px solid transparent;
}

input:focus {
   background-color: white;
   outline: 0;
   border-color: #4354FE;
}

label {
   font-weight: bold;
   color: black;
   padding-left: 5px;
   margin-bottom: 8px;
   display: block;
}

.col-2-2 {
   grid-column-start: 1;
   grid-column-end: 3;
}

/* 
	====================================

		BUTTON & INPUT

	==================================== 
*/
input[type="submit"],
.btn-submit,
.btn-skip {
   width: auto;
   display: inline-block;
   font-size: 13px;
   font-weight: bold;
   max-height: 40px;
   min-height: 40px;
   letter-spacing: 1px;
   font-family: "Lato", "Arial", sans-serif;
}

.btn-anchor {
   line-height: 40px;
   padding: 0 30px;
}

input[type="submit"],
.btn-submit {
   background-color: #4354FE;
   color: white;
}

.btn-skip {
   background-color: #ddd;
   color: #666;
}

.btn-salir{
   font-size: 0.8rem;
   color: #505050;
   width: 50%;
   padding: 0;
   line-height: 0.9rem;
   margin: 0;
   font-weight: 500;
}

.full-width {
   width: 100%;
}

.btn-full-width {
   display: block;
   width: 100%;
}

.full-width {
   width: 100%;
}

input[type="submit"]:hover,
.btn-submit:hover,
.btn-skip:hover,
input[type="submit"]:focus,
.btn-submit:focus,
.btn-skip:focus {
   background-color: black;
   color: white;
}

.btn-permission {
   letter-spacing: 0;
   font-size: 18px;
}

input[type="submit"]:hover,
input[type="submit"]:focus {
   background-color: black;
}

input[type="checkbox"] {
   height: auto;
   display: inline-block;
   width: auto;
   border: 0;
   height: 20px;
   width: 20px;
   top: -3px;
}

input[type="submit"]:disabled {
   cursor: not-allowed;
   opacity: 0.8;
   background-color: #999;
}

input[type="submit"].disabled {
   /* cursor: not-allowed; */
   opacity: 0.8;
   background-color: #999;
}

.btn-icon {
   padding: 0px 15px;
   border-radius: 31px;
   font-weight: 700;
   font-size: 13px;
}

.btn-icon img {
   padding-left: 15px;
}

.btn-white {
   background-color: white;
   color: #4354FE;
   padding-top: 0;
   padding-bottom: 0;
   min-height: 36px;
}

button {
   background-color: inherit;
   border: 0;
}

button:focus {
   outline: inherit;
}

:focus {
   outline: inherit;
}

.btn-with-icon {
   position: relative;
   padding-right: 34px;
}

.btn-with-icon img {
   position: absolute;
   right: 6px;
   top: 10px;
   width: 15px;
}

.btn-gray {
   background-color: #e2e2e2;
   color: #4b4b4b;
}

.btn-mini {
   padding: 5px 15px;
   min-height: 30px;
}

/* 
	====================================

		HOME PAGE

	==================================== 
*/

.hoy-component {
   overflow: hidden;
}

.mainnav {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   -webkit-box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2);
   box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2);
   z-index: 10;
   background-color: white;
   display: flex;
   height: 55px;
   right: 0;
   margin: 0;
   padding: 4px 0 2px 0;
}

.mainnav button {
   display: inline-block;
}

.mainnav button span {
   display: block;
   font-size: 10px;
   line-height: 12px;
   letter-spacing: 0.5px;
   color: black;
   font-weight: bold;
   font-family: "Lato", "Arial", sans-serif;
}

.mainnav .navitem {
   width: 25%;
   text-align: center;
   display: inline-block;
}

.mainnav button:focus svg,
.mainnav button:focus span,
.mainnav button.active span,
.mainnav button.active svg {
   fill: #4354FE;
   color: #4354FE;
}

.square-message {
   display: block;
   border-radius: 3px;
   padding: 8px 10px;
   margin-bottom: 10px;
   font-size: 14px;
}

.square-message a {
   color: white;
}

.square-message img {
   margin-right: 10px;
}

.square-message-default {
   background-color: #999;
   color: white;
}

.square-message-recomendacion {
   background-color: #725f82;
   color: white;
}

.square-message-pastillero {
   background-color: #ff6666;
   color: white;
}

.square-message-activacion-conductual {
   background-color: #9acade;
   color: white;
}

.middday-bg {
   background-repeat: no-repeat;
   background-position: top center;
}

/* 
	====================================

		SIDE MENU

	==================================== 
*/
.sidenav {
   height: 100%;
   width: 100%;
   position: fixed;
   z-index: 11;
   top: 0;
   left: -100%;
   background-color: #4354FE;
   overflow-x: hidden;
   transition: 0.5s;
}

.sidenav ul {
   padding: 0;
   margin: 0;
   margin-top: 40px;
}

.sidenav ul li {
   list-style: none;
   margin-top: 5px;
   padding-left: 12px;
}

.sidenav ul li img {
   margin-right: 10px;
   margin-top: -2px;
}

.sidenav a {
   padding: 8px;
   text-decoration: none;
   color: white;
   transition: 0.3s;
   font-size: 13px;
   font-weight: 700;
   letter-spacing: 1px;
   text-transform: uppercase;
   display: inline-block;
   border-bottom: 0px;
}

.sidenav a:hover {
   color: #f1f1f1;
}

.sidenav .closebtn {
   position: absolute;
   top: 0;
   right: 25px;
   font-size: 36px;
   margin-left: 50px;
}

#main {
   transition: margin-left 0.5s;
   padding: 20px;
}

@media screen and (max-height: 450px) {
   .sidenav {
      padding-top: 15px;
   }

   .sidenav a {
      font-size: 18px;
   }
}

.sidenav .userdetail {
   padding: 15px;
}

.sidenav .userdetail span {
   font-size: 16px;
   color: white;
   font-family: "Lato", "Arial", sans-serif;
   font-weight: 800;
}

.userdetail {
   border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.button-back {
   position: absolute;
   right: -2px;
   top: 45%;
}

.button-back button {
   padding: 0;
}

.open-aside {
   padding: 5px;
}

.fecha-actual {
   padding-right: 20px;
   font-size: 16px;
}

.home-top-nav {
   padding-top: 15px;
   padding-bottom: 15px;
   overflow: hidden;
}

/* 
	====================================

		RECURSOS

	==================================== 
*/
.inline-navegation {
   display: inline-block;
   padding: 0;
   margin: 0;
   margin-top: 15px;
}

.inline-navegation li {
   list-style: none;
   display: inline-block;
   margin-right: 15px;
}

.inline-navegation li button {
   font-family: "Lato", "Arial", sans-serif;
   font-size: 14px;
   color: #4354FE;
   letter-spacing: 0.5px;
   opacity: 0.8;
   cursor: pointer;
   padding: 5px 15px;
   border: 1px solid transparent;
}

.inline-navegation li button.activo {
   border: 1px solid #4354FE;
   border-radius: 100px;
   padding: 5px 15px;
   opacity: 1;
}

.page-name h1 {
   color: #4354FE;
   font-size: 22px;
   font-weight: 700;
   margin-bottom: 0;
   margin-top: 5px;
   margin-bottom: 0px;
   font-family: "Lato", "Arial", sans-serif;
}

.header-nav-spacing {
   padding-left: 0px;
   padding-bottom: 10px;
}

.recursos-page {
   background-color: rgba(84, 61, 208, 0.1);
   padding: 4px 0 80px 0;
   overflow: hidden;
}

.resource-list a {
   display: inline-block;
   color: #4354FE;
}

.resource-list h2 {
   font-size: 22px;
   color: #4354FE;
   font-weight: 800;
   letter-spacing: -0.2px;
   line-height: 24px;
   margin-bottom: 5px;
}

.next-button a {
   background-color: #4354FE;
   border-radius: 20px;
   padding: 4px 10px;
   font-size: 12px;
   font-weight: 700;
   letter-spacing: 1.5px;
   color: white;
}

.next-button a:hover {
   opacity: 0.8;
   text-decoration: none;
}

.next-button img {
   padding-left: 12px;
}

.resource-item {
   padding: 0;
}

.fullwidth {
   width: 100%;
}

.fullh-image {
   height: 100%;
   width: auto;
   max-height: inherit !important;
}

/* 
	====================================

		RECURSOS ASIGNADOS

	==================================== 
*/

.resource-background {
   background-color: white;
   border-radius: 5px;
   overflow: hidden;
   -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.resource-asign-description {
   padding-top: 15px;
   padding-bottom: 10px;
}

.resource-asign-description p {
   color: #4b4b4b;
   font-size: 13px;
   margin-bottom: 20px;
}

.resource-asign-photo {
   position: relative;
   height: 100%;
   padding: 15px;
}

.bg-shadow,
.bg-shadow-inverted {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}

.bg-shadow {
   background: -moz-linear-gradient(top,
         rgba(84, 61, 208, 1) 0%,
         rgba(84, 61, 208, 0) 100%);
   background: -webkit-linear-gradient(top,
         rgba(84, 61, 208, 1) 0%,
         rgba(84, 61, 208, 0) 100%);
   background: linear-gradient(to bottom,
         rgba(84, 61, 208, 1) 0%,
         rgba(84, 61, 208, 0) 100%);
}

.bg-shadow-inverted {
   background: -moz-linear-gradient(top,
         rgba(84, 61, 208, 0) 0%,
         rgba(84, 61, 208, 1) 100%);
   background: -webkit-linear-gradient(top,
         rgba(84, 61, 208, 0) 0%,
         rgba(84, 61, 208, 1) 100%);
   background: linear-gradient(to bottom,
         rgba(84, 61, 208, 0) 0%,
         rgba(84, 61, 208, 1) 100%);
}

.resource-asign-photo h2,
.resource-asign-photo span {
   z-index: 2;
   position: relative;
}

.resource-asign-photo h2 {
   color: white;
   font-size: 17px;
   font-weight: 800;

   line-height: 20px;
   display: block;
}

.resource-asign-photo span {
   color: white;
   font-size: 14px;
   bottom: 15px;
   position: absolute;
}

.resource-asign-photo {
   background-size: cover;
}

.resource-background {
   margin-bottom: 25px;
}

/* 
	====================================

		PSICOEDUCACION: USOS HIGIENE

	==================================== 
*/
.carousel .slider-wrapper.axis-horizontal .slider .psicoeducacion img {
   padding: 0px;
}

.caption-side {
   padding: 0px 15px;
}

.caption-side h3 {
   background-color: white;
   border-radius: 20px;
   display: block;
   position: relative;
   z-index: 2;
   max-width: 80%;
   margin: auto;
   margin-top: -15px;
   font-size: 22px;
   font-weight: 800;
   letter-spacing: -0.2px;
   color: #ddb790;
   margin-bottom: 10px;
   padding: 6px 10px 0px 10px;
}

.caption-side p {
   font-size: 14px;
   color: #333;
   line-height: 20px;
}

.usos .carousel .control-dots {
   position: relative;
   margin-top: 0;
}

.usos .carousel .caption-side p {
   margin-bottom: 10;
}

.slide-full-height div,
.slide-full-height .carousel.carousel-slider,
.slide-full-height .carousel .slider-wrapper,
.slide-full-height .carousel .slider-wrapper.axis-horizontal .slider,
.slide-full-height .carousel .slide {
   height: 100%;
}

.slide-full-height .carousel.carousel-slider {
   margin-top: -7px;
}

/* 
	====================================

		SELECCION DEL AVATAR

	==================================== 
*/

.elegir-avatar .carousel .control-dots {
   position: relative;
   margin-top: 30px;
}

.avatar .carousel .slider-wrapper.axis-horizontal .slider .slide img {
   height: 310px;
}

/* 
	====================================

		OBJETIVOS

	==================================== 
*/
.light-title {
   font-weight: 300;
}

.light-title strong {
   font-weight: 700;
}

.objectives-higiene label {
   font-weight: 300;
   color: #4b4b4b;
   text-align: center;
   border: 1px solid #e2e2e2;
   border-radius: 3px;
   display: block;
   min-height: 60px;
   position: relative;
   padding: 10px;
   z-index: 2;
}

.objectives-higiene label strong {
   font-weight: 700;
}

.objectives-higiene input[type="checkbox"] {
   position: absolute;
   z-index: 1;
   width: 0;
   height: 0;
}

.objectives-higiene input[type="checkbox"]:checked+label {
   background-color: #a8977a;
   color: white;
}

.hour-select {
   background-color: #a8977a;
   color: white;
   padding: 10px 15px;
   border-radius: 3px;
}

.hour-select span {
   font-size: 14px;
   font-weight: 600;
   display: inline-block;
   line-height: 18px;
}

.hour-inline span,
.hour-select select {
   font-size: 26px;
   font-weight: 800;
   color: white;
}

.hour-select select {
   background-color: inherit;
   padding: 0;
   border: 0;
   display: inline-block;
   width: auto;
   text-align: center;
}

.hour-inline span {
   margin-right: 12px;
   margin-left: 8px;
   top: -4px;
   position: relative;
}

.hour-inline .row {
   margin: auto;
}

.boxed-border-bottom {
   border-bottom: 1px solid rgba(255, 255, 255, 0.2);
   padding-bottom: 10px;
   margin-bottom: 15px;
}

.objetivo-last {
   padding-top: 10px;
   padding-bottom: 10px;
}

.objetivo-last span {
   font-size: 14px;
   color: #8e8e8e;
   letter-spacing: 0px;
}

.objetivo-last .sleep-hour {
   font-size: 18px;
   color: #806b58;
   font-weight: 600;
}

/* 
	====================================

		HIGIENE DEL SUEÑO

	==================================== 
*/
.higiene-resource-item-link {
   display: block;
   width: 100%;
   border-bottom: 1px solid rgb(53, 189, 173, 0.6);
   padding-bottom: 15px;
   margin-bottom: 15px;
   position: relative;
}

.higiene-resource-item-link h2 {
   font-weight: 800;
   font-size: 20px;
   margin-bottom: 0px;
}

.higiene-resource-item-link:hover {
   text-decoration: none;
}

/* .higiene-resource-item-link:after {
   display: block;
   position: absolute;
   bottom: 0;
   content: "";
   height: 1px;
   width: 100%;
   background-color: rgba(255, 255, 255, 0.3);
} */
.higiene-faqs h2 {
   font-weight: 300;
}

.higiene-faqs h2 strong {
   font-weight: 800;
}

.label-level {
   border: 1px solid white;
   border-radius: 3px;
   position: relative;
   z-index: 2;
   width: 50px;
   height: 36px;
   text-align: center;
   margin-top: 5px;
   margin-left: -8px;
}

.label-level:after {
   position: absolute;
   border: 2px outset rgb(53, 189, 173, 0.2);
   border-radius: 3px;
   top: 0px;
   left: -2px;
   content: "";
   display: block;
   width: 108%;
   height: 107%;
   z-index: 1;
}

.label-level .level-number {
   text-align: center;
   display: block;
   position: absolute;
   top: -15px;
   z-index: 3;
   margin: auto;
   width: 100%;
}

.label-level .level-number span {
   background-color: #5ddbcc;
   display: inline-block;
   padding: 0 4px;
   font-size: 26px;
   font-weight: 800;
   line-height: 30px;
}

.label-level .level-name {
   font-size: 12px;
   display: block;
   padding-bottom: 5px;
   position: relative;
   margin-top: 15px;
   font-weight: 600;
}

.desafio-semanal-link {
   background-color: white;
   border-radius: 3px;
   padding: 0 10px;
   width: 100%;
   display: block;
}

.desafio-semanal-link h4 {
   color: #5a8489;
   font-size: 12px;
   font-weight: bold;
   letter-spacing: 1px;
   line-height: 20px;
   display: block;
   margin: 0px;
}

.desafio-semanal-link h2 {
   color: #5ddbcc;
}

.desafio-semanal-link .next-arrow {
   position: absolute;
   right: 15px;
   top: 28%;
   z-index: 2;
}

.desafio-semanal-link .next-arrow svg,
.desafio-semanal-link .next-arrow path {
   fill: #5ddbcc;
}

.shadow {
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

/* 
	====================================

		HISTORIAS PARA DORMIR

	==================================== 
*/

.historia-slide-item {
   display: inline-block;
   width: 120px;
   height: 160px;
   border: 2px solid transparent;
   margin: 0;
   transform: skew(-10deg);
   overflow: hidden;
   border-radius: 10px;
   margin-left: 8px;
}

.historia-picture-bg {
   width: 140%;
   height: 100%;
   background-size: contain;
   transform: skew(10deg);
   background-position: center;
   background-repeat: no-repeat;
   position: absolute;
   z-index: 1;
   left: -20%;
}

.historia-picture {
   height: 100%;
   position: relative;
   box-sizing: border-box;
}

.historia-para-dormir-bg {
   position: absolute;
   top: 0;
   bottom: 0;
   height: 100%;
   min-height: 600px;
   left: 0;
   right: 0;
   z-index: 0;
   background: rgb(82, 129, 205);
   background: -moz-radial-gradient(center,
         ellipse cover,
         rgba(82, 129, 205, 1) 0%,
         rgba(39, 61, 119, 1) 54%,
         rgba(39, 61, 119, 1) 54%,
         rgba(21, 29, 52, 1) 100%);
   background: -webkit-radial-gradient(center,
         ellipse cover,
         rgba(82, 129, 205, 1) 0%,
         rgba(39, 61, 119, 1) 54%,
         rgba(39, 61, 119, 1) 54%,
         rgba(21, 29, 52, 1) 100%);
   background: radial-gradient(ellipse at center,
         rgba(82, 129, 205, 1) 0%,
         rgba(39, 61, 119, 1) 54%,
         rgba(39, 61, 119, 1) 54%,
         rgba(21, 29, 52, 1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5281cd', endColorstr='#151d34', GradientType=1);
}

.header-over-historias {
   background-color: #213376;
}

.historia-para-dormir {
   padding-bottom: 52px;
}

.slide-bg-image {
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
}

.historia-para-dormir-over-bg {
   background-image: url(../images/historia-description-bg.png);
   background-size: inherit;
   background-position: top center;
   background-repeat: no-repeat;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 0;
   opacity: 0.8;
}

.historia-item-description {
   position: relative;
   z-index: 2;
   padding-top: 20px;
   padding-bottom: 20px;
}

.historia-item-description h1 {
   font-size: 24px;
   color: #5ddbcc;
   font-weight: 800;
   line-height: 28px;
}

.historia-item-description p {
   color: rgba(255, 255, 255, 0.8);
   font-size: 13px;
   line-height: 18px;
   font-weight: 500;
}

.header-over-historias span {
   color: white;
   font-weight: 600;
   letter-spacing: 0;
}

.image-responsive {
   width: 100%;
}

.historia-slide {
   position: relative;
   background-color: rgba(0, 0, 0, 0.2);
   margin-top: 20px;
}

.scroll-historias {
   padding: 10px 30px 5px 30px;
}

.scroll-historias .active {
   border: 2px solid #5ddbcc;
}

.historia-slide .carousel .slide {
   background: inherit;
   padding: 0 2px;
}

.historia-completada {
   background-image: url(../images/iconos/icon-historia-completada.svg);
   background-size: cover;
   background-repeat: no-repeat;
   width: 25px;
   height: 25px;
   display: block;
   position: absolute;
   right: 18px;
   top: 6px;
   z-index: 2;
}

.historia-default {
   background-image: url(../images/fondos/historias-bg.svg);
   background-size: cover;
   background-repeat: no-repeat;
   background-color: #435b8a;
   content: "";
   display: block;
   width: 100%;
   height: 100%;
   position: absolute;
}

.selected .historia-slide-item {
   border: 2px solid #2fde80;
}

.historia-slide:before,
.historia-slide:after {
   position: absolute;
   content: "";
   width: 20px;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.2);
   top: 0;
   z-index: 3;
}

.historia-slide:before {
   left: 0;
   background: -moz-linear-gradient(left,
         rgba(0, 0, 0, 0.4) 0%,
         rgba(0, 0, 0, 0) 100%);
   background: -webkit-linear-gradient(left,
         rgba(0, 0, 0, 0.4) 0%,
         rgba(0, 0, 0, 0) 100%);
   background: linear-gradient(to right,
         rgba(0, 0, 0, 0.4) 0%,
         rgba(0, 0, 0, 0) 100%);
}

.historia-slide:after {
   right: 0;
   background: -moz-linear-gradient(left,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0.4) 100%);
   background: -webkit-linear-gradient(left,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0.4) 100%);
   background: linear-gradient(to right,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0.4) 100%);
}

.scroll-historias {
   overflow: auto;
   white-space: nowrap;
}

.historia-slide-bloqueada .historia-default {
   background-image: url(../images/fondos/historia-bg-bloqueada.svg);
}

.historia-slide-bloqueada .historia-picture-bg {
   background-image: url(../images/iconos/icono-lock.svg);
   background-position: center center;
   background-size: 50px;
   background-repeat: no-repeat;
}

/* 
	====================================

		HISTORIA PARA DORMIR: DETALLE

	==================================== 
*/
.play-video {
   height: 400px;
}

.recurso-view {
   padding: 30px 20px;
}

.recurso-view h3 {
   font-size: 18px;
   margin-bottom: 10px;
   font-weight: bold;
   color: #4354FE;
}

.view-extraDetail {
   margin-bottom: 20px;
   color: #999;
   font-size: 13px;
}

.view-extraDetail img {
   margin-top: -3px;
   margin-right: 5px;
}

.view-description p {
   font-size: 14px;
   color: #999;
   line-height: 22px;
}

.view-description {
   border-bottom: 1px solid #ccc;
}

.view-extraDetail span {
   border-left: 1px solid #ccc;
   padding-left: 15px;
   margin-right: 10px;
}

.view-extraDetail span:first-child {
   border-left: 0px;
   padding-left: 0px;
}

/* 
	====================================

		ANIMATE - OPTIONS

	==================================== 
*/

.animated.delay-1hs {
   animation-delay: 0.5s;
}

.delay-0-2s {
   animation-delay: 0.2s;
}

.delay-0-4s {
   animation-delay: 0.4s;
}

.delay-0-6s {
   animation-delay: 0.6s;
}

.delay-0-8s {
   animation-delay: 0.8s;
}

.animated.delay-2hs {
   animation-delay: 1.5s;
}

.animated.delay-3hs {
   animation-delay: 2.5s;
}

/* 
	====================================

		Musica de relajacion

	==================================== 
*/
.musica-de-relajacion {
   padding-bottom: 52px;
}

.section-bg {
   position: absolute;
   top: 0;
   bottom: 0;
   height: 100%;
   min-height: 600px;
   left: 0;
   right: 0;
   z-index: 0;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
}

.musica-de-relajacion .mid-music-relajacion {
   width: 100%;
   position: absolute;
   z-index: 2;
}

.musica-de-relajacion .black-shadow {
   background: -moz-linear-gradient(top,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0) 35%,
         rgba(0, 0, 0, 1) 60%,
         rgba(0, 0, 0, 1) 99%);
   background: -webkit-linear-gradient(top,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0) 35%,
         rgba(0, 0, 0, 1) 60%,
         rgba(0, 0, 0, 1) 99%);
   background: linear-gradient(to bottom,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0) 35%,
         rgba(0, 0, 0, 1) 60%,
         rgba(0, 0, 0, 1) 99%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
}

.musica-de-relajacion .picture-background,
.musica-de-relajacion .historia-picture-bg {
   background-size: cover;
   background-position: center center;
}

.musica-de-relajacion .historia-slide-bloqueada .historia-picture-bg {
   background-size: 50px;
}

.musica-de-relajacion .historia-item-description h1 {
   margin-bottom: 0;
}

.musica-de-relajacion .historia-item-description h1,
.musica-de-relajacion .duracion-recurso {
   color: white;
}

.header-over-white {
   background-color: white;
}

/* 
	====================================
		Refuerzo positivo
	==================================== 
*/
.refuerzo-positivo-capture {
   position: relative;
   z-index: 1;
}

.refuerzo-positivo {
   position: absolute;
   overflow: hidden;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   line-height: 80vh;
   background-color: rgba(255, 255, 255, 0.7);
}

.refuerzo-positivo-bg {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   background-image: url(../images/iconos/refuerzo-positivo-bg.svg);
   background-repeat: no-repeat;
   background-position: center center;
   background-size: contain;
}

.bg-degree {
   transform: rotate(45deg);
}

/* 
	====================================

		CONTROLES REPRODUCCION VIDEO

	==================================== 
*/

input[type="range"] {
   -webkit-appearance: none;
   /* Hides the slider so that custom slider can be made */
   width: 100%;
   /* Specific width is required for Firefox. */
   background: rgba(255, 255, 255, 0.1);
   /* Otherwise white in Chrome */
   height: 8px;
   padding: 0;
   border-radius: 4px;
}

input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
}

input[type="range"]:focus {
   outline: none;
   /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
   width: 100%;
   cursor: pointer;
   /* Hides the slider so custom styles can be added */
   background: transparent;
   border-color: transparent;
   color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   border: none;
   height: 8px;
   width: 30px;
   border-radius: 4px;
   background: rgba(255, 255, 255, 0.5);
   cursor: pointer;
   margin-top: 0px;
   /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
   box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
   border: 1px solid #000000;
   height: 36px;
   width: 16px;
   border-radius: 3px;
   background: #ffffff;
   cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
   box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
   border: 1px solid #000000;
   height: 36px;
   width: 16px;
   border-radius: 3px;
   background: #ffffff;
   cursor: pointer;
}

/* 
====================================

   VIDEOATENCION

==================================== 
*/
.videoatencion {
   max-width: 500px;
   margin: auto;
   padding-top: 20px;
}

.videoatencion .nombre-paciente {
   color: #4354FE;
   font-size: 14px;
}

.videoatencion .nombre-paciente span {
   line-height: 19px;
}

.videoatencion .nombre-paciente strong {
   padding-right: 10px;
   border-right: 1px solid #ccc;
   margin-right: 10px;
}

.iniciar-teleconsulta button.btn-primary-rounded {
   background-color: #4354FE;
   border-radius: 20px;
   padding: 2px 20px;
   font-weight: bold;
   width: auto;
   display: inline-block;
   font-size: 13px;
   font-weight: bold;
   max-height: 40px;
   min-height: 40px;
   font-family: "Lato", "Arial", sans-serif;
   border: 0px;
   color: white;
}

.iniciar-teleconsulta {
   border: 1px solid #d9daee;
   padding: 20px;
   border-radius: 4px;
   border-bottom: 3px solid #d9daee;
   margin-top: 15px;
}

.medicDataName {
   color: #4354FE;
   display: block;
   font-size: 16px;
   display: block;
   line-height: 18px;
}

.medicDataSpeciality {
   font-size: 13.5px;
   display: block;
   color: #999;
   text-transform: uppercase;
   letter-spacing: 0.5px;
}

.patient-video {
   min-height: 400px;
   background-color: #f2f2f2;
   position: relative;
   overflow: hidden;
}

.medic-video {
   position: absolute;
   right: 10px;
   top: 10px;
   border-radius: 5px;
   width: 100px;
   height: 80px;
   overflow: hidden;
   z-index: 2;
   margin: auto;
   float: right;
}

.medic-video .agora-player__box {
   left: 50%;
   top: 50%;
   height: 100%;
   width: auto;
   -webkit-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
}

.videoButtonState button {
   background-color: #555;
   border-radius: 3px;
   padding: 10px 15px;
   font-size: 14px;
   color: #ddd;
   letter-spacing: 0.5px;
}

.patient-video .patientvideo {
   width: 100%;
   height: 450px;
   position: absolute;
}

.patient-video .mobileapp-pacient {
   height: 100%;
}

.default-message {
   padding-top: 6%;
   text-align: center;
}

.mobile-user {
   height: 100%;
}

.ReactModal__Overlay {
   z-index: 2;
}

.mobileapp-pacient-container {
   height: 100vh;
}

.mobileapp-pacient-container .patient-video {
   border-radius: 2px;
   height: 100vh;
   padding: 0px;
}

.mobileapp-pacient-container .header-meet {
   background: white;
   padding: 6px 0;
   overflow: hidden;
   position: relative;
   z-index: 10;
   height: 100%;
}

.mobileapp-pacient-container .header-meet .row {
   height: 100%;
}

.mobileapp-pacient-container .header-meet-bg {
   background-color: white;
   position: relative;
   z-index: 12;
   height: 15vh;
   min-height: 12vh;
   max-height: 13vh;
}

.mobileapp-pacient-container .body-call-video {
   height: 85vh;
   overflow: hidden;
   width: 100%;
   position: relative;
}

.mobileapp-pacient-container .body-call-footer {
   background-color: white;
   overflow: hidden;
   width: 100%;
   position: relative;
   padding: 0px;
   text-align: center;
   position: fixed;
   bottom: 0;
   left: 0;
}

.popup .popup-container {
   max-width: 300px;
   padding: 20px;
   background-color: #f2f2f2;
   box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.4);
   border-radius: 2px;
}

.popup .popup-container p {
   font-size: 13.5px;
   font-weight: 400;
}

.popup .popup-container h3 {
   font-size: 16px;
}

.popup .popup-container select {
   width: 100%;
   font-size: 16px;
   border: 0px;
   height: 40px;
   line-height: 40px;
   margin-top: 5px;
   margin-bottom: 20px;
   color: #666;
   border: 1px solid #ddd;
}

.mobileapp-pacient-container .body-call-footer .d-flex {
   position: relative;
}

.mobileapp-pacient-container .body-call-footer .d-flex .menu {
   position: absolute;
   left: 7px;
   top: 7px;
}

.color-primario {
   color: #4354FE;
}



.body-call-footer-buttons {
   justify-content: center;
}

.mobileapp-pacient-container .body-call-footer .d-flex {
   text-align: center;
   display: block !important;
}

.mobileapp-pacient-container .body-call-footer .d-flex p {
   font-size: 12px;
   display: inline-block;
}

.mobileapp-pacient-container .body-call-footer .d-flex button {
   font-size: 12px;
   margin: 4px !important;
   padding: 7px !important;
   font-weight: bold;
}

.body-call-footer .d-flex button img {
   opacity: 0.6;
}

.body-call-footer .d-flex button img:hover {
   opacity: 1;
}

.mobileapp-pacient-container .mobileapp-pacien {
   height: 100%;
   width: 100%;
   position: relative;
}

.mobileapp-pacient-container .medic-video {
   border-radius: 5px;
   width: 100px;
   height: 80px;
   overflow: hidden;
   z-index: 2;
   margin: auto;
   float: right;
   position: absolute;
   top: 8px;
   right: 10px;
}

/* 
====================================

   TEST VIDEO

==================================== 
*/
.canvas-testvideo {
   background-color: #f2f2f2;
   width: 100%;
   height: 10px;
}

.default-test img {
   max-height: 200%;
}

.testmic {
   position: relative;
   width: 100%;
   overflow: hidden;
   padding: 20px;
}

.testmic-fullh {
   height: 100%;
   overflow: hidden;
}

.testmic p {
   color: #4b4b4b;
   font-size: 14px;
   margin-bottom: 20px;
}

.testmic h2 {
   font-size: 1.2rem;
   font-weight: 300;
   margin-top: 5px;
   letter-spacing: -0.3px;
}

.testvideo {
   position: absolute;
   width: 100%;
   height: 100vh;
   left: 0;
   top: 0;
   right: 0;
   z-index: 1;
   bottom: 0;
}

.testvideo video {
   width: 100%;
   height: 100vh;
   position: relative;
   z-index: 2;
   top: -5%;
   /*-webkit-transform: translate(-35%, 0%);
   -ms-transform: translate(-35%, 0%);
   transform: translate(-35%, 0%);*/
}

.bg-testvideo {
   width: 100%;
   height: 100vh;
   background-color: black;
   position: absolute;
   z-index: 1;
}

.testmic-options {
   bottom: 0;
   position: absolute;
   left: 0;
   right: 0;
   box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.1);
   padding: 2%;
   z-index: 3;
}

.testmic-options button {
   width: 100%;
   background-color: #4354FE;
   border: 0px;
   font-weight: 600;
}

.testmic-options button img {
   margin-left: 20px;
}

.testmic-options button.btn-success {
   background-color: rgb(40, 181, 106);
}

/* 
====================================

   ATENCION MODAL

==================================== 
*/
.atencion-ahora h3 {
   font-size: 18px;
   font-weight: 500;
   margin-bottom: 20px;
}

.atencion-ahora span {
   font-size: 14px;
   letter-spacing: 0.5px;
   color: #666;
}

.atencion-ahora h3 strong {
   color: #4354FE;
   font-weight: bold;
}

.atencion-ahora .modal-footer {
   display: block;
}

/* 
====================================

  INTRODUCCION A LA APP
  Hoy

==================================== 
*/
.mobileAppIntroduction h1 {
   margin-bottom: 15px;
}

.mobileAppIntroduction p {
   font-size: 15px;
   color: #888;
   font-weight: 400;
   font-family: "Lato", "Arial", sans-serif;
   letter-spacing: 0px;
}

.mobileAppIntroduction em {
   color: #4354FE;
   font-style: initial;
}

.mobileAppIntroduction .gray-button input {
   background-color: #e2e2e2;
   color: #4b4b4b;
}

.home-waiting {
   text-align: center;
   margin: 20px 0px;
   border-radius: 5px;
   padding: 20px;
   margin-top: 10px;
   border-top: 1px solid #ccc;
   border: 1px solid #ccc;
}

.home-waiting span {
   color: #666;
   font-size: 14px;
   padding: 0px;
   margin-bottom: 10px;
   display: block;
}

.home-waiting h1 {
   font-weight: 500;
   font-size: 18px;
   margin-bottom: 0px;
}

.home-waiting h1 strong,
.home-waiting h1 em {
   color: #4354FE;
}

.homeRecomendations ul {
   padding: 0px;
   margin: 0px;
   list-style: none;
}

.homeRecomendations ul li {
   position: relative;
   padding-left: 40px;
   margin-bottom: 18px;
   line-height: 20px;
   color: #707070;
   font-size: 15px;
}

.homeRecomendations ul li strong,
.homeRecomendations em {
   color: #4354FE;
}

.homeRecomendations h3 em {
   font-style: normal;
}

.homeRecomendations ul li:first-child img {
   top: 0;
}

.homeRecomendations ul li img {
   position: absolute;
   left: 0px;
   top: 5px;
}

.homeAdditionalButton:hover {
   text-decoration: none;
   opacity: 0.8;
}

.homeAdditionalButton {
   display: block;
   position: relative;
   padding: 15px;
   border-radius: 5px;
   -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
   text-decoration: none;
}

.homeAdditionalButton .titleButton {
   font-size: 16px;
   color: #4354FE;
   line-height: 20px;
   display: block;
}

.homeAdditionalButton .titleButton strong {
   font-weight: bold;
   display: block;
   line-height: 18px;
}

.homeAdditionalButton .goButton {
   position: absolute;
   right: 15px;
   top: 25px;
   font-size: 13px;
}

.homeAdditionalButton .goButton img {
   margin-left: 5px;
}

.homeRecomendations .carousel .control-dots .dot {
   padding-left: 0px;
}

.homeRecomendations .carousel .slide {
   background-color: inherit;
}

.homeRecomendations .homeRecomendationSlider li {
   text-align: left;
   padding: 0px 10px;
   color: black;
}

.homeRecomendationItem {
   display: flex;
}

.dashed-separator {
   border: 1px dashed #f2f2f2;
   width: 100%;
   height: 1px;
}

.homeRecomendationItem .recomendationIcon {
   margin-right: 20px;
   margin-top: 8px;
}

.homeRecomendations .homeRecomendationSlider .carousel .slider-wrapper.axis-horizontal .homeRecomendationItem img {
   padding: 0px;
   width: 30px;
   position: relative;
   left: inherit;
   top: inherit;
}

.pacienteCuenta .dropdown-menu {
   left: initial !important;
   right: 0px !important;
   top: 100% !important;
   transform: initial !important;
}

.pacienteCuenta .dropdown-item {
   font-size: 14px;
}

.pacienteCuenta .dropdown-menu .cerrarSesion {
   color: #4354FE;
}

.pacienteCuenta .dropdown-menu .cerrarSesion img {
   vertical-align: middle;
   display: inline-block;
   margin-top: -3px;
}

.pacienteCuenta .accountDetails .rutAccount {
   border-right: 1px solid #ccc;
   font-size: 13px;
   padding-right: 12px;
}

.dropdown-micuenta {
   background-color: inherit;
   border: 1px solid #ddd;
   padding: 8px;
   color: #4354FE;
   font-size: 13px;
   min-height: auto;
   margin-top: 14px;
}

.dropdown-micuenta:hover {
   border: 1px solid #4354FE;
   background-color: inherit;
   color: #4354FE;
}

.dropdown-micuenta:focus {
   background-color: #f2f2f2;
   color: #4354FE;
}

.show>.btn-secondary.dropdown-toggle {
   background-color: inherit;
   color: #4354FE;
}

@media screen and (max-width: 480px) {
   .home-waiting h1 {
      font-size: 15px;
      letter-spacing: 0.1px;
   }

   .home-waiting .btn-submit {
      height: auto;
      max-height: inherit;
      line-height: 18px;
      letter-spacing: 0.3px;
      font-weight: 500;
      font-size: 14px;
   }
}




/* 
====================================
  GUIA GuiaVideoatencion
==================================== 
*/
.guiastep {
   position: relative;
   padding-left: 40px;
   padding-bottom: 20px;
}

.guiastep .number {
   background-color: #4354FE;
   border-radius: 100%;
   color: white;
   display: inline-block;
   width: 30px;
   height: 30px;
   font-weight: bold;
   text-align: center;
   padding-top: 2px;
   position: absolute;
   top: -4px;
   left: 0px;
   z-index: 2;
}

.guiastep .number-line {
   position: absolute;
   z-index: 1;
   height: 100%;
   width: 2px;
   background-color: #dfdfdf;
   opacity: 0.6;
   left: 13px;
}

.guiastep h3 {
   font-size: 18px;
   font-weight: bold;
   color: black;
}

.btn-simulated {
   line-height: 27px;
   border-radius: 0px;
}

button.no-pointer {
   cursor: inherit;
}

.guia-titulo-icon {
   position: relative;
   padding-left: 40px;
}

.guia-titulo-icon a {
   display: inline-block;
   position: absolute;
   left: 4px;
   top: 9px;
}

.guia-titulo-icon a:hover {
   opacity: 0.8;
}

.fixed-button-buttom {
   position: fixed;
   bottom: 0;
   left: auto;
   max-width: 600px;
   margin-bottom: 0 !important;
   padding: 10px 15px;
   background-color: white;
   -webkit-box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2);
   box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2);
}

.fixed-button-buttom .btn-simulated {
   width: 100%;
}

/* 
====================================
  Olvido contraseña
==================================== 
*/
.alert-message {
   padding: 12px;
   font-weight: normal;
   color: #d15b5b;
   border: 1px solid #ff7575;
   border-radius: 3px;
}

.alert-success {
   padding: 12px;
   background-color: white;
   font-weight: normal;
   color: #02b5a0;
   border: 1px solid #02b5a0;
   border-radius: 3px;
}

/* 
====================================
  Resumen
==================================== 
*/
.box-border {
   border: 1px solid #d9d9d9;
   border-radius: 5px;
}

.box-heading {
   color: #4354FE;
   font-size: 16px;
   padding: 10px;
   border-bottom: 1px solid #d9d9d9;
}

.box-body {
   padding: 1px;
}

.box-body table {
   width: 100%;
   margin: auto;
   border: 0px;
}

.box-body table td {
   padding: 8px 15px;
   font-size: 14px;
   font-weight: 400;
}

.box-body table tr:nth-child(even) {
   background-color: #f2f2f2;
}

.duracionAtencion .banner-bg {
   border: 1px solid #d9d9d9;
   color: #666;
   padding: 10px;
   border-radius: 3px;
   font-size: 13px;
}

.duracionAtencion .banner-bg strong {
   float: right;
   color: black;
}

/*
===================================
  OVERWRITE MODAL STYLES
===================================
*/
.ReactModalPortal .ReactModal__Content--after-open {
   padding: 15px !important;
}

.alertModal h1 {
   font-weight: 500;
   font-size: 16px;
}

/*
===================================
  Pre-Ingreso
===================================
*/
.movile-app {
   height: 100%;
   overflow: hidden;
   width: auto;
   min-height: 100%;
   min-height: 100vh;
   display: flex;
   align-items: center;
}

.movile-app .testvideo {
   position: inherit;
   width: auto;
   height: auto;
   left: auto;
   top: 0;
   right: 0;
   z-index: 1;
   bottom: 0;
   max-width: 80%;
   margin: auto;
}

.movile-app .bg-testvideo {
   width: 100%;
   height: 100%;
   background-color: transparent;
   position: absolute;
   z-index: 5;
   left: 0;
   top: 0;
   margin-top: -10%;
   content: "";
}

.movile-app .testvideo video {
   width: auto;
   height: auto;
   position: relative;
   z-index: 2;
   top: 0%;
   margin-top: -10%;
   text-align: center;
   margin: auto;
   width: 100%;
   padding-bottom: 45px;
   border-radius: 4px;
}

.movile-app .testmic-options {
   bottom: 35px;
   position: absolute;
   box-shadow: inherit;
   padding: 0;
   z-index: 6;
   left: -5%;
   width: 110%;
}

.movile-app .testmic-options button {
   box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.movile-app small {
   text-align: center;
   display: block;
}

@media only screen and (max-width: 576px) {
   .videoatencion-medic {
      padding: 15px 30px;
   }

   .videoatencion-medic .iniciar-teleconsulta {
      text-align: center;
   }

   .videoatencion-medic .iniciar-teleconsulta-step .text-right {
      text-align: center !important;
   }

   .videoatencion-medic .iniciar-teleconsulta-step img {
      margin-bottom: 20px;
   }
}

@media only screen and (max-width: 480px) {
   .mobileapp-pacient-container .header-meet .videoButtonState img {
      width: 100%;
   }

   .mobileapp-pacient-container .header-meet .medicData {
      padding-top: 10px;
   }

   .mobileapp-pacient-container .header-meet .medicDataName {
      font-size: 14px;
      line-height: 16px;
   }
}

/*
===================================
  Encuesta
===================================
*/
.question-row {
   border-bottom: 1px solid #f2f2f2;
   padding-bottom: 25px;
   margin-bottom: 25px;
}

.question-title {
   font-size: 14px;
   color: black;
   font-weight: bold;
   display: block;
   margin-bottom: 10px;
   padding-left: 12px;
}

.question-checkbox {
   display: flex;
   background-color: #f1effb;
   padding: 5px 10px;
   border-radius: 30px;
}

.question-checkbox span {
   display: flex;
   width: 50%;
}

.question-checkbox span label {
   line-height: 32px;
   padding-left: 10px;
   vertical-align: middle;
   margin-bottom: 0;
   padding: 0px;
   font-weight: normal;
   letter-spacing: 0;
   color: black;
   opacity: 0.6;
}

.question-checkbox .roundbloque,
.question-row .roundbloque {
   width: 18px;
   height: 18px;
   display: block;
   content: "";
   border-radius: 100%;
   background-color: #4354FE;
   opacity: 0.4;
   margin-top: 1px;
   margin-left: 9px;
}

.question-checkbox span:first-child label {
   margin-right: 14px;
}

.question-checkbox span input {
   width: 20px;
   height: 32px;
   margin: 0 5px;
   display: inline-block;
}

.question-description {
   font-size: 14px;
   line-height: 16px;
   color: #4b4b4b;
   margin-bottom: 15px;
   display: block;
   padding-left: 12px;
   opacity: 0.8;
}

.agora-player__placeholder {
   display: none !important;
}

/*
===================================
  Loose connection
===================================
*/
.modal-warning h3 {
   font-weight: bold;
   color: #ff6666;
}

.modal-warning .modal-content {
   border-bottom: 6px solid #ff6666;
}

.modal-warning .modal-content span {
   display: block;
   text-align: center;
}

.sk-circle {
   margin: 30px auto;
   width: 100px;
   height: 100px;
   position: relative;
}

.sk-circle .sk-child {
   width: 100%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
}

.sk-circle .sk-child:before {
   content: "";
   display: block;
   margin: 0 auto;
   width: 15%;
   height: 15%;
   background-color: #4354FE;
   border-radius: 100%;
   -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
   animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
   -webkit-transform: rotate(30deg);
   -ms-transform: rotate(30deg);
   transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
   -webkit-transform: rotate(60deg);
   -ms-transform: rotate(60deg);
   transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
   -webkit-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
   -webkit-transform: rotate(120deg);
   -ms-transform: rotate(120deg);
   transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
   -webkit-transform: rotate(150deg);
   -ms-transform: rotate(150deg);
   transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
   -webkit-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
   -webkit-transform: rotate(210deg);
   -ms-transform: rotate(210deg);
   transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
   -webkit-transform: rotate(240deg);
   -ms-transform: rotate(240deg);
   transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
   -webkit-transform: rotate(270deg);
   -ms-transform: rotate(270deg);
   transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
   -webkit-transform: rotate(300deg);
   -ms-transform: rotate(300deg);
   transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
   -webkit-transform: rotate(330deg);
   -ms-transform: rotate(330deg);
   transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
   -webkit-animation-delay: -1.1s;
   animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
   -webkit-animation-delay: -1s;
   animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
   -webkit-animation-delay: -0.9s;
   animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
   -webkit-animation-delay: -0.8s;
   animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
   -webkit-animation-delay: -0.7s;
   animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
   -webkit-animation-delay: -0.6s;
   animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
   -webkit-animation-delay: -0.5s;
   animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
   -webkit-animation-delay: -0.4s;
   animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
   -webkit-animation-delay: -0.3s;
   animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
   -webkit-animation-delay: -0.2s;
   animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
   -webkit-animation-delay: -0.1s;
   animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {

   0%,
   80%,
   100% {
      -webkit-transform: scale(0);
      transform: scale(0);
   }

   40% {
      -webkit-transform: scale(1);
      transform: scale(1);
   }
}

@keyframes sk-circleBounceDelay {

   0%,
   80%,
   100% {
      -webkit-transform: scale(0);
      transform: scale(0);
   }

   40% {
      -webkit-transform: scale(1);
      transform: scale(1);
   }
}

/*
===================================
  Waiting ROOM
===================================
*/
.enter-wait-room h1 {
   font-size: 22px;
   color: #4354FE;
   font-weight: 300;
   margin-bottom: 10px;
}

.enter-wait-room h1 strong {
   font-weight: bold;
}

.button-enter {
   background-color: #533dd02b;
   border-radius: 30px;
   padding: 5px 20px;
}

.button-enter span {
   font-size: 14px;
}

.button-enter .btn-submit {
   letter-spacing: 0;
   font-size: 14px;
   border-radius: 3px;
   padding: 0 10px;
}

.info-warning {
   border-radius: 4px;
   background-color: rgba(84, 61, 208, 0.2);
   color: black;
}

.info-warning strong {
   color: #4354FE;
}

/*
===================================
  Recurso Item
===================================
*/
.recurso-item h2 {
   font-size: 16px;
}

.recurso-item {
   background-color: white;
   border-radius: 3px;
   box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
   margin: 15px 0px;
   overflow: hidden;
}

.recurso-item .rounded-image {
   height: 100%;
}

.recurso-item .rounded-image a {
   display: block;
   width: 100%;
   position: relative;
   min-height: 200px;
   height: 200px;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
}

.recurso-item .rounded-image a:hover {
   opacity: 0.6;
}

.recurso-item .duracion {
   position: absolute;
   left: 10px;
   bottom: 10px;
   color: white;
   z-index: 2;
   font-weight: bold;
   font-size: 14px;
}

.image-overlay {
   opacity: 0.7;
}

.vid-options {
   padding: 15px 10px;
}

.opacity-05 {
   opacity: 0.5;
}

@media only screen and (max-width: 400px) {
   .view-extraDetail img {
      display: block;
      margin-bottom: 4px;
   }

   .recurso-view h3 {
      margin-bottom: 15px;
   }
}

/*
===================================
  Reminder
===================================
*/
.reminder-row {
   border-bottom: 1px solid #ddd;
   padding: 10px 0px;
}

.reminder-row a:hover {
   text-decoration: none;
}

.reminder-name,
.reminder-date {
   display: block;
}

.reminder-name {
   font-size: 16px;
   line-height: 18px;
   color: #4354FE;
   font-weight: 400;
}

.reminder-date {
   font-size: 14px;
   color: #555;
}

.detalle-actividad .view-description h3 {
   font-size: 14px;
}

.detalle-actividad .view-description p {
   margin-bottom: 0;
}

.detalle-actividad .view-description .pending-status {
   color: #666;
   font-size: 12px;
   text-transform: uppercase;
   letter-spacing: 0.3px;
}

.actividad-cta small {
   font-size: 13px;
   line-height: 16px;
   display: block;
   margin-bottom: 10px;
}

.reminder-item-list a {
   display: block;
   cursor: pointer;
}

.reminder-item-list a:hover {
   opacity: 0.7;
}

.row-today small {
   font-size: 14px;
   letter-spacing: 1px;
   color: #999;
}

.sin-actividades h3 {
   font-size: 20px;
   color: #4354FE;
}

.progresoEstrellas {
   font-size: 40px;
   color: #999;
}

.progresoEstrellas .completed {
   color: #4354FE;
}

.progresoTitulo h4 {
   font-size: 1.2em;
   color: #4354FE;
   margin-bottom: 0;
}

.progresoBarra {
   height: 0.5em;
}

.progresoBarra .progress-bar {
   background-color: #2fde80;
}

.actividadFondo {
   background-color: #f2f2f2;
}

.completedAnimation {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: inherit;
   background-color: rgba(255, 255, 255, 0.8);
   text-align: center;
}

.completedAnimation .title {
   margin-top: 15px;
   font-size: 24px;
   font-weight: bold;
   color: black;
}

.box_resumen_semanal {
   height: 80vh !important;
   width: 100%;
}

.w-1 {
   width: 1% !important;
}

.w-24 {
   width: 24% !important;
}

/*
===================================
  MEDIC SITE
===================================
*/
.medicSite .card-body {
   padding: 15px 10px;
}

.medicSite h2 {
   font-size: 16px;
   font-weight: bold;
   margin-bottom: 0;
   color: black;
}

.medicSite .btn-iniciar {
   background-color: #4354FE;
   color: white;
   font-size: 13px;
   padding: 10px 8px;
   border-radius: 3px;
   font-weight: bold;
   letter-spacing: 0;
}

.medicSite .btn-iniciar:hover {
   background-color: black;
}

.medicSite .btn-iniciar img {
   margin-right: 10px;
   margin-top: -2px;
}

.medicSite .btn-recursos:hover {
   text-decoration: none;
}

.medicSite .patientStatus {
   font-size: 14px;
   letter-spacing: 0.5px;
}

.medicSite .patientStatus:before {
   display: inline-block;
   content: "";
   width: 8px;
   height: 8px;
   background-color: red;
   border-radius: 100%;
   margin-right: 5px;
}

.medicSite .little-shadow {
   box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1);
   border: 1px solid rgba(0, 0, 0, 0.125);
}

.medicSite .videoatencion {
   padding-top: 0;
   margin-top: 15px;
   width: 100%;
}

.medicSite .iniciar-teleconsulta {
   margin: 0px;
   border: 0px;
   padding: 0;
   background-color: #d9daee;
   border-radius: 6px;
}

.medicSite .iniciar-teleconsulta-step {
   padding: 15px;
   text-align: center;
}

.searching {
   position: fixed;
   bottom: 55px;
   left: 15px;
   right: 15px;
   z-index: 10;
   text-align: center;
}

.searching-container {
   background-color: #28a745;
   color: white;
   font-size: 16px;
   padding: 8px 18px;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   font-weight: bold;
   display: inline-block;
}

.searching .spinner-border {
   width: 20px;
   height: 20px;
   border-width: 0.15em;
   margin-right: 10px;
}

.videoatencion-medic .patient-video .sticky-wrapper .patientvideo {
   position: absolute;
}

.videoatencion-medic .patient-video .sticky .patientvideo {
   position: fixed;
   top: 10px;
   left: 10px;
   width: 180px;
   height: 140px;
   z-index: 10;
}

.audioLevel {
   background-color: #ddd;
   height: 10px;
   display: block;
   border-radius: 10px;
   position: relative;
}

.noiseLevel {
   background-color: #5ddbcc;
   position: absolute;
   left: 0;
   top: 0;
   height: 100%;
   display: block;
   border-radius: 10px;
   -webkit-transition: width 500ms ease-in-out;
   -moz-transition: width 500ms ease-in-out;
   -o-transition: width 500ms ease-in-out;
   transition: width 500ms ease-in-out;
}

.check-item {
   /*background-color: rgba(84, 61, 208, 0.2);*/
   padding: 20px 15px;
   border-radius: 3px;
   box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
   position: relative;
}

.little-shadow {
   box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
}

.check-item h3 {
   font-size: 16px;
   font-weight: bold;
   color: black;
   margin-bottom: 5px;
}

.check-item h4 {
   font-size: 14px;
   font-weight: bold;
   color: black;
   margin-bottom: 5px;
}

.check-item span {
   font-size: 14px;
   line-height: 18px;
}

.check-item .btn {
   background-color: #4354FE;
   color: white;
   padding: 0px 12px;
   min-height: 33px;
   font-size: 13px;
   font-weight: bold;
   border-radius: 2px;
}

.check-item .btn:hover {
   background-color: black;
}

.check-item .btn-success,
.check-item .btn-danger {
   padding: 0px 5px;
   border: 0px;
   font-weight: bold;
   width: 100%;
}

.check-item .btn-success {
   background-color: #10bf65;
}

.check-item .btn-danger {
   background-color: #ff6666;
}

.check-verificado {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(16, 191, 101, 0.9);
   z-index: 2;
}

.check-verificado span {
   margin: 0;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: white;
   font-size: 20px;
   font-weight: bold;
   line-height: 40px;
   vertical-align: middle;
}

.minLevel {
   position: absolute;
   left: 50%;
   top: -4px;
   width: 2px;
   background-color: #ff6666;
   height: 18px;
}

.step-active {
   border-bottom: 4px solid #4354FE;
}

.step-unactive {
   border-bottom: 4px solid #f2f2f2;
}

.step-main-class {
   position: relative;
}

.border-top {
   border-top: 1px solid #ccc;
}

.check-devices select {
   width: 100%;
}

.check-devices {
   width: 100%;
}

.videoatencion .litte-spinner {
   width: 17px;
   height: 17px;
   border: 0.2em solid currentColor;
   border-right-color: transparent;
   margin-right: 5px;
   position: absolute;
   top: 2px;
   left: 0px;
   z-index: 2;
   color: rgb(0, 0, 0, 0.4);
}

.videoatencion .status-styles {
   color: #666;
   float: right;
   display: flex;
}

.statusPointer {
   width: 20px;
   height: 20px;
   position: relative;
   margin-right: 5px;
}

.statusName {
   display: inline-block;
   padding-right: 5px;
}

.statusCircle {
   position: relative;
   width: 15px;
   height: 15px;
   background-color: #ddd;
   display: block;
   content: "";
   border-radius: 100%;
   margin-top: 3px;
   transition: 1s background-color;
}

.red-status {
   background-color: red;
}

.green-status {
   background-color: #2fde80;
}

.orange-status {
   background-color: rgb(255, 161, 55);
}

/*
===================================
  Resumen de Atención
===================================
*/
.colTable .headerTable {
   border-top: 1px solid #ccc;
   border-bottom: 1px solid #ccc;
}

.colTable .headerTable span,
.colTable .bodyTable span {
   font-size: 13px;
   color: black;
   display: block;
   line-height: 16px;
   font-family: "Roboto", "Arial", sans-serif;
}

.colTable .bodyTable span.gray {
   color: #4D4F5C;
}

.colTable .bodyTable .dayResumeItem:nth-child(even) {
   background-color: #EFEEF7;
}

.colTable .bodyTable .dayResumeItem img,
.colTable .bodyTable .dayResumeItem .catName svg {
   margin-right: 4px;
}

.colTable .bodyTable .dayResumeItem .catName {
   text-transform: capitalize;
}

.colTable .dayResumeItem .completada {
   color: #009923;
   font-weight: normal;
}

.colTable .dayResumeItem .incompleta {
   color: #d15b5b;
   font-weight: normal;
}

.colTable .dayResumeItem .pendiente {
   color: #999;
}

.dayResume .dayResumeSelection span {
   color: #82838D;
   font-size: 14px;
   display: block;
   line-height: 16px;
   font-family: "Roboto", "Arial", sans-serif;
}

.dayResume .dayResumeSelection h4 {
   font-size: 16px;
   color: #4354FE;
   font-weight: bold;
   font-family: "Roboto", "Arial", sans-serif;
}

.navigationMenuItem {
   display: inline-block;
   border: 1px solid #ddd;
   width: 50%;
   height: 100%;
   text-align: left;
   border-top-right-radius: 3px;
   padding: 14px 18px;
   position: relative;
   cursor: pointer;
   opacity: 0.8;
}

.navigationMenuItem svg {
   position: absolute;
   right: 15px;
   top: 28px;
   font-size: 27px;
}

.navigationMenuItem h3 {
   font-size: 16px;
   font-weight: bold;
   margin-bottom: 5px;
}

.navigationMenuItem p {
   margin-bottom: 0px;
   font-size: 13px;
   line-height: 16px;
   opacity: 0.8;
}

.navigationMenuItem:first-child {
   border-right: 0px;
   border-top-left-radius: 3px;
   border-top-right-radius: 0px;
}

.navigationMenu .activate {
   background-color: #4354FE;
   color: white;
   opacity: 1;
}

.navigationMenu .activate p {
   color: white;
}

.navigationMenu {
   display: flex;
}

.recurso_entrada button {
   padding: 10px;
   height: 100%;
   width: 100%;
}

.activity-name {
   font-size: 14px;
   line-height: 16px;
   display: block;
}

.medicSite .centro-recursos {
   border-top: 0px;
}

.centro-recursos input {
   font-size: 14px;
   letter-spacing: 0px;
}

.centro-recursos select {
   width: 100%;
}

.topfilters div:first-child {
   margin-right: 2%;
   width: 48%;
}

.topfilters div {
   width: 50%;
}

.topfilters select {
   font-size: 14px;
   font-family: "Arial", sans-serif;
   height: 35px;
   display: inline-block;
   border: 0px;
   border: 1px solid rgba(64, 43, 177, 0.3);
   background-color: white;
   color: rgba(67, 66, 93, 1);
}

.audioLevel {
   background-color: #ddd;
   height: 10px;
   display: block;
   border-radius: 10px;
   position: relative;
}

.noiseLevel {
   background-color: #5ddbcc;
   position: absolute;
   left: 0;
   top: 0;
   height: 100%;
   display: block;
   border-radius: 10px;
   -webkit-transition: width 500ms ease-in-out;
   -moz-transition: width 500ms ease-in-out;
   -o-transition: width 500ms ease-in-out;
   transition: width 500ms ease-in-out;
   max-width: 100%;
}

.check-item {
   /*background-color: rgba(84, 61, 208, 0.2);*/
   padding: 20px 15px;
   border-radius: 3px;
   box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
   position: relative;
}

.little-shadow {
   box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
}

.check-item h3 {
   font-size: 16px;
   font-weight: bold;
   color: black;
   margin-bottom: 5px;
}

.check-item h4 {
   font-size: 14px;
   font-weight: bold;
   color: black;
   margin-bottom: 5px;
}

.check-item span {
   font-size: 14px;
   line-height: 18px;
}

.check-item .btn {
   background-color: #4354FE;
   color: white;
   padding: 0px 12px;
   min-height: 33px;
   font-size: 13px;
   font-weight: bold;
   border-radius: 2px;
}

.check-item .btn:hover {
   background-color: black;
}

.check-item .btn-success,
.check-item .btn-danger {
   padding: 0px 5px;
   border: 0px;
   font-weight: bold;
   width: 100%;
}

.check-item .btn-success {
   background-color: #10bf65;
}

.check-item .btn-danger {
   background-color: #ff6666;
}

.check-verificado {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(16, 191, 101, 0.9);
   z-index: 2;
}

.check-verificado span {
   margin: 0;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: white;
   font-size: 20px;
   font-weight: bold;
   line-height: 40px;
   vertical-align: middle;
}

.minLevel {
   position: absolute;
   left: 50%;
   top: -4px;
   width: 2px;
   background-color: #ff6666;
   height: 18px;
}

.step-active {
   border-bottom: 4px solid #4354FE;
}

.step-unactive {
   border-bottom: 4px solid #f2f2f2;
}

.step-main-class {
   position: relative;
}

.border-top {
   border-top: 1px solid #ccc;
}

.check-devices select {
   width: 100%;
}

.check-devices {
   width: 100%;
}

.inline-navegation li a.no-pointer {
   pointer-events: none;
}

.litte-spinner {
   width: 17px;
   height: 17px;
   border: 0.2em solid currentColor;
   border-right-color: transparent;
   margin-right: 5px;
   position: absolute;
   top: 2px;
   left: 0px;
   z-index: 2;
   color: rgb(0, 0, 0, 0.4);
}

.status-styles {
   color: #666;
   display: flex;
   list-style: none;
   align-items: center;
   justify-content: center;
}

.statusPointer {
   width: 20px;
   height: 20px;
   position: relative;
   margin-right: 5px;
}

.statusName {
   display: inline-block;
   padding-right: 5px;
}

.statusCircle {
   position: relative;
   width: 15px;
   height: 15px;
   background-color: #ddd;
   display: block;
   content: "";
   border-radius: 100%;
   margin-top: 3px;
   transition: 1s background-color;
}

.red-status {
   background-color: red;
}

.green-status {
   background-color: #2fde80;
}

.orange-status {
   background-color: rgb(255, 161, 55);
}

.newMessageIndicator {
   width: 18px;
   height: 18px;
   background-color: red;
   position: absolute;
   right: 33%;
   border-radius: 100%;
   top: -4px;
}

/*
===================================
  Ayuda al usuario // Permisos
===================================
*/
.ayuda-permisos .guiastep h3 {
   font-size: 14px;
}

.ayuda-permisos .number {
   width: 24px;
   line-height: 18px;
   height: 24px;
   padding-top: 3px;
   position: relative;
   top: 0px;
   font-size: 16px;
   margin-bottom: 4px;
}

.ayuda-permisos .guiastep {
   padding-left: 0px;
}

.ayuda-permisos .guiastep p {
   font-size: 12px;
   line-height: 16px;
   font-weight: 400;
}

.ayuda-permisos .border-image {
   border: 1px solid #ddd;
   border-radius: 0px;
}

.ayuda-permisos .card-header {
   padding: 0px;
}

.ayuda-permisos .card-header button {
   display: block;
   width: 100%;
   color: black;
   font-size: 16px;
   font-weight: bold;
   text-align: left;
   position: relative;
}

.ayuda-permisos .card-header button small {
   display: block;
   font-size: 13px;
   color: #666;
}

.ayuda-permisos .card-header button:hover {
   background-color: #4354FE;
}

.ayuda-permisos .card-header button:hover,
.ayuda-permisos .card-header button:focus {
   text-decoration: none;
}

.ayuda-permisos .card-header button:hover,
.ayuda-permisos .card-header button:hover small {
   color: white;
}

.ayuda-permisos .card-header button svg {
   position: absolute;
   right: 20px;
   top: 15px;
}

.ownModal {
   position: fixed;
   background-color: rgba(0, 0, 0, 0.3);
   left: 0px;
   right: 0px;
   top: 0px;
   bottom: 0px;
   z-index: 10;
}

/*
   ========================
   CitasProximas.js
   ========================
*/

#loading {
   height: 100%;
   position: absolute;
   top: 0px;
   width: 100%;
}

#loading .col-md-12 {
   position: relative;
   height: 100%;
}

.loading-spinner {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
}

.loading-spinner div {
   position: absolute;
   top: 33px;
   width: 13px;
   height: 13px;
   border-radius: 50%;
   animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-spinner div:nth-child(1) {
   left: 8px;
   animation: loading-spinner1 0.6s infinite;
   background: rgba(64, 43, 177, 0.2);
}

.loading-spinner div:nth-child(2) {
   left: 8px;
   animation: loading-spinner2 0.6s infinite;
   background: rgba(64, 43, 177, 0.4);
}

.loading-spinner div:nth-child(3) {
   left: 32px;
   animation: loading-spinner2 0.6s infinite;
   background: rgba(64, 43, 177, 0.7);
}

.loading-spinner div:nth-child(4) {
   left: 56px;
   animation: loading-spinner3 0.6s infinite;
   background: rgba(64, 43, 177, 1);
}

@keyframes loading-spinner1 {
   0% {
      transform: scale(0);
   }

   100% {
      transform: scale(1);
   }
}

@keyframes loading-spinner3 {
   0% {
      transform: scale(1);
   }

   100% {
      transform: scale(0);
   }
}

@keyframes loading-spinner2 {
   0% {
      transform: translate(0, 0);
   }

   100% {
      transform: translate(24px, 0);
   }
}

.citasProximas .littleTitle h4 {
   font-size: 12px;
   font-weight: bold;
   color: #999;
   text-transform: uppercase;
   letter-spacing: 1px;
   margin-bottom: 0px;
}

.citaDetail {
   text-align: left;
   background-color: #f2f2f2;
}

.citaDetail .tag {
   font-size: 10.5px;
   font-weight: bold;
   color: #999;
   text-transform: uppercase;
   letter-spacing: 1px;
   padding: 0px;
   margin: 0px;
}

.citaDetail .info {
   padding: 0px;
   margin: 0px;
   font-size: 12px;
   letter-spacing: 0.5px;
   color: black;
}

.citaDetail .infosub {
   color: #4354FE;
}

.citaLejana {
   border: 1px solid #ddd;
   border-radius: 0px;
}

.citaLejana h5 {
   font-weight: 400;
   font-size: 16px;
   line-height: 22px;
}

.citaLejana svg {
   font-size: 22px;
   color: #999;
}

.citaLejana h6 {
   font-size: 18px;
   font-weight: bold;
}

.citaHoy {
   box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
   border-bottom: 2px solid #4354FE;
}

.citaHoy h4 {
   font-size: 20px;
   font-weight: 300;
}

.citaHoy h4 strong {
   text-decoration: underline;
   color: #4354FE;
   font-weight: 600;
}

.citaHoy h4 em {
   color: black;
   font-style: normal;
   font-size: 16px;
   font-weight: 400;
}

@media screen and (max-width: 450px) {
   .button-enter {
      border-radius: 6px;
      padding: 4px ​20px 10px 20px;
   }

   .header-redgesam {
      margin: 0;
   }

   .bonoDetail a svg {
      display: none;
   }

   .mainnav .navitem {
      padding: 0px;
   }

   .mainnav button span {
      display: block;
      font-size: 8px;
      letter-spacing: 1px;
      font-weight: bold;
   }
}

.visible-xs {
   display: none;
}

@media screen and (max-width: 450px) {

   .hidden-xs {
      display: none;
   }

   .visible-xs {
      display: initial;
   }
}

.bonoDetail {
   padding-bottom: 15px;
}

.bonoDetail em {
   color: #4354FE;
}

.bonoDetail span {
   margin: 0px;
}

.bonoDetail a {
   border: 1px solid #4354FE;
   display: inline-block;
   padding: 6px 10px;
   font-size: 14px;
   color: #4354FE;
   border-radius: 3px;
}

.footerPush {
   height: 100px;
   display: block;
   width: 100%;
}

.dropdown-header {
   padding: 0px;
   margin-bottom: 10px;
}

.dropdown-item {
   font-size: 14px;
   padding: 0px;
   margin: 4px 0px;
}

.dropdown-item:hover {
   background-color: transparent;
}

/*
   ============================
   Toas
   ============================
*/
.Toastify__toast-container {
   width: 100% !important;
   height: 100%;
   top: 0px !important;
   right: 0px !important;
   left: 0px;
   bottom: 0px;
}

.Toastify__toast-container .Toastify__toast small {
   font-size: 10px;
   letter-spacing: 1px;
   font-weight: bold;
   text-transform: uppercase;
   border: 1px solid #ec4c4c;
   color: #ec4c4c;
   padding: 4px 10px;
   border-radius: 20px;
   margin-bottom: 20px;
   display: inline-block;
}

.Toastify__toast-container .Toastify__toast {
   width: 100%;
   height: 100%;
   padding: 30px;
   cursor: inherit;
   max-height: inherit;
   justify-content: center;

}

.Toastify__toast-container .Toastify__toast .toastBody {
   max-width: 600px;
   margin: auto;
}

.Toastify__toast-container .Toastify__toast h1 {
   font-size: 30px;
   font-weight: 300;
   color: #4354FE;
   margin: 0px;
   text-align: center;
   margin-bottom: 15px;
}

.Toastify__toast-container .Toastify__toast p {
   color: #999;
   font-size: 16px;
   font-weight: 400;
   line-height: 20px;
   text-align: center;
}

.Toastify__toast-container .Toastify__toast p strong {
   color: #4354FE;
   font-weight: 500;
}

.ver-historia embed,
.ver-historia object {
   height: 100vh;
}

.btn-download {
   color: #4354FE;
}

.btn-download:hover {
   color: black;
}

/* Resmumen Atención */
.reviewRating span.MuiTypography-body1 {
   font-size: 0.8rem;
   line-height: 1.2;
}

.w-20 {
   width: 20% !important;
}

.default-message50 {
   padding-top: 50%;
   text-align: center;
}

/*
   ============================
   Netflix styles
   ============================
*/
.homev2 .header-home-temporal h1 {
   font-size: 21px;
   color: #4354FE;
   display: block;
   line-height: 24px;
   font-weight: bold;
   margin-bottom: 0px;
}

.homev2 .header-home-temporal h1 span {
   font-size: 14px;
   line-height: 14px;
   color: #505050;
   display: block;
   font-weight: 500;
}

.homev2 .btn-submit {
   border-radius: 3px;
   letter-spacing: 0px;
   font-size: 16px;
}

.homev2 .detalle-recurso h2 {
   font-size: 20px;
   color: #505050;
   margin: 0px;
   font-weight: bold;
   line-height: 26px;
   margin-bottom: 12px;
}

.homev2 .detalle-recurso p {
   font-size: 14px;
   color: #505050;
   opacity: 0.8;
   font-family: "Lato", "Arial", sans-serif
}

.homev2 .detalle-recurso .btn-submit,
.homev2 .card-bar .btn-submit {
   border-radius: 3px;
   letter-spacing: 0px;
   padding-left: 14px;
   padding-right: 14px;
   font-size: 14px;
}

.homev2 .card-bar {
   background-color: white;
   box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
   width: 100%;
   margin: 0 16px;
}

.homev2 .card-bar p {
   font-weight: bold;
   color: #505050;
   opacity: 0.7;
   margin-bottom: 0px;
}

.homev2 .rounded-image {
   display: block;
   width: 100%;
   height: 100%;
   position: relative;
   border-radius: 4px;
   overflow: hidden;
   min-height: 200px;
}

.homev2 .rounded-image:hover {
   opacity: 0.7;
}

.homev2 .rounded-image a {
   display: block;
   height: 100%;
   width: 100%;
   background-size: cover;
   cursor: pointer;
}

.webpushmessage {
   position: relative;
   display: flex;
   height: 40px;
   align-items: center;
}

.webpushmessage svg {
   margin-right: 4px;
}

.webpushmessage span {
   margin: 6px 0px;
   font-size: 13px;
   color: black;
   font-weight: 400;
   line-height: 16px;
   display: block;
}

.webpushmessage h4 {
   font-size: 14px;
   font-weight: 400;
   margin-bottom: 0px;
}

.webpush-enabled svg {
   color: #088944;
}

.webpush-enabled h4 {
   color: #088944;
}

.webpush-disabled h4 {
   color: #d15b5b;
}

.webpush-disabled svg {
   color: #d15b5b;
}

.overlay-icon {
   color: white;
   font-size: 30px;
   width: 100%;
   text-align: center;
   display: flex;
   align-items: center;
   align-content: center;
   justify-content: center;
   height: 100%;
   z-index: 2;
   position: relative;
}

.overlay-icon svg {
   font-size: 60px;
}

.overlay-icon:hover {
   opacity: 0.8;
}

.recursos_v2 .recursoCard h2 {
   font-size: 13px;
   text-align: center;
   margin-bottom: 0px;
   font-weight: 500;
   color: black;
}

.recursos_v2 .recursoCard .rounded-image {
   display: block;
   width: 100%;
   position: relative;
   min-height: 200px;
   height: 200px;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
}

.recursos_v2 .header-nav p {
   font-size: 13px;
}

.recursos_v2 .recurso-destacado {
   min-height: 340px;
   background-size: cover;
   background-position: center;
   border-radius: 3px;
   overflow: hidden;
   position: relative;
}

.recursos_v2 .recurso-destacado button {
   background-color: white;
   color: #4354FE;
   border-radius: 3px;
}

.recursos_v2 .recurso-destacado button:hover {
   background-color: #4354FE;
   color: white;
}

.recursos_v2 .caption-destacado {
   position: absolute;
   bottom: 0px;
   width: 100%;
   padding: 20px;
   color: white;
   z-index: 2;
}

.recursos_v2 .caption-destacado h2 {
   font-weight: bold;
   font-size: 20px;
   text-shadow: 1px 3px 4px #00000042;
}

.recursos_v2 .caption-destacado p {
   color: white;
   margin-bottom: 0px;
   font-family: 'Lato', 'Arial', sans-serif;
   font-size: 14px;
   letter-spacing: 0.3px;
   font-weight: 400;
   text-shadow: 1px 1px 4px #00000042;
}

.recursos_v2 .home-top-nav p {
   font-size: 13px;
}

.recursos_v2 .bg-shadow-inverted {
   background: linear-gradient(to bottom,
         rgba(84, 61, 208, 0) 0%,
         rgba(84, 61, 208, 1) 60%);
}

.recursos_v2 .recurso-item button {
   padding: 0px;
   width: 100%;
   height: 100%;
}

.recursos_v2 .recurso-item button:hover .nombreRecurso,
.recursos_v2 .recurso-item button:focus .nombreRecurso {
   background-color: #4354FE;
}

.recursos_v2 .recurso-item button:hover .nombreRecurso h2,
.recursos_v2 .recurso-item button:focus .nombreRecurso h2 {
   color: white;
}

.section-sub-title h4 {
   font-size: 12px;
   font-weight: bold;
   color: #999;
   text-transform: uppercase;
   letter-spacing: 1px;
   margin-bottom: 0px;
}

@media screen and (max-width: 480px) {
.recursos_v2 .recursoCard .rounded-image {
  min-height: 160px;
  height: 160px;
}


}

@media screen and (max-width: 700px) {
.recursos_v2 {
  overflow: hidden;
}
.slick-prev,
.slick-next {
  display: none !important;
}
}

.valida-cuenta{
  color: #4354FE;
  height: 110vh;
  /*IMPORTANTE*/
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Specific styles for active links */
.btn-recursos:active,
.btn-recursos::after,
.btn-recursos::before,
.btn-recursos:visited
{
   color: black;
   border: 0.5px solid #4354FE;
}
.link-active
{
   color: black;
   border: 0.5px solid #4354FE;
}

/*
   ============================
   Testing Section
   ============================
*/
.test-teleconsulta{
  background-color: #e7e4f6;
  border-radius: 6px;
  padding:16px 20px;
}
.test-teleconsulta h3{
  font-size: 16px;
  color: #4354FE;
  display: block;
  line-height: 24px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif; 
}
.test-teleconsulta p{
  font-family: 'Poppins', sans-serif; 
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color:black;
}