.notification_square{
    background-color:white;
    padding:15px;
    border-radius: 4px;
    margin:14px 0px;
    position:relative;
    padding-bottom: 20px;
}

.notification_square .MuiLinearProgress-root{
    position: absolute;
    bottom:0px;
    width:100%;
    left:0px;
    right:0px;
}

.notification_square button{
    border: #4354FE 1px solid;
    color: #4354FE;
    font-size: 13px;
    padding: 10px 8px;
    border-radius: 3px;
    font-weight: bold;
    letter-spacing: 0;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
}

.notification_square button:disabled,
.notification_square button[disabled]{
    background-color: #ddd;
    color: #888;
    border-color: #ddd;
    cursor: not-allowed;
}

.notification_square button:hover{
    background-color:#4354FE;
    color:white;
}

.notification_square .sendResult{
    font-size:15px;
    margin-bottom:4px;
}

.notification_square small,
.notification_square p{
    font-size: 13px;
    text-align: left;
    display: inline-block;
    color: #333;
}