@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap');

/*
  Styles for wizzard title
*/

.wizzard-heading{
  margin:auto;
  text-align: center;
  margin-bottom: 20px;
}
.wizzard-heading .step-number{
  font-size:30px;
  font-family: 'Poppins', sans-serif; 
  font-weight: 500;
  color:#E2E2E2;
}
.wizzard-heading .step-title{
  font-size:22px;
  color:#4354FE;
  font-weight: 600;
  font-family: 'Poppins', sans-serif; 
}


/*
  Styles for checking box
*/
.wizzard-checking{
  margin-bottom:40px;
}

.verify-devices-items{
  display:block;
  padding:20px;
  margin:0px;
  border:1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.verify-devices-items .is-checking .validation-name{
   color:black;
}

.verify-devices-items li{
   list-style: none;
   position: relative;
   padding-left:26px;
   margin-bottom: 12px;
}
.verify-devices-items li:last-child{
  margin-bottom: 0px;
}

.validation-name{
   color:#999;
}

.valid-state .validation-name{
  color:#10bf65;
}
.not-valid-state .validation-name{
  color:#ff6666;
}

.verify-devices-items .result{
  width: 28px;
  height: 28px;
  position: relative;
  top: -6px;
  left: -4px;
}

.result-container{
  position: absolute;
  left: 0px;
  top: 3px;
  border-radius: 100%;
  border: 2px solid #ddd;
  height: 20px;
  width: 20px;
}

.result-container .spinner{
  position: relative;
  top: -2.5px;
  left: -1.5px;
}

.success{
  color:#10bf65;
}
.error{
  color:#ff6666;
}


/*
  Styles for wizzard errors
*/
.wizzard-errors h2{
  font-size:20px;
  color:#ff6666;
  font-weight: 400;
  font-family: 'Poppins', sans-serif; 
  text-align: center;
}
.wizzard-errors .wizzard-error{
  display:block;
  padding:20px;
  margin:0px;
  border:1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.wizzard-errors .wizzard-error h3{
  font-size:14px;
  color:#ff6666;
  font-weight: 600;
  font-family: 'Poppins', sans-serif; 
}
.wizzard-errors .wizzard-error p{
  font-family: 'Poppins', sans-serif; 
  font-size:13px;
  font-weight: 400;
  margin-bottom: 0;
}
.wizzard-errors .wizzard-error button{
  border-radius: 3px;
  letter-spacing: 0.25px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif; 
  font-weight: 500;
}