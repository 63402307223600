.review{
  padding: 20px;
  margin: 0px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 10%);
}

.review h1{
  font-size: 18px;
  font-weight: bold;
  color: #4354FE;
  font-family: 'Poppins', sans-serif;
}

.review p,
.review textarea{
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.review span{
  
}

.review-title{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.review textarea{
  width:100%;
  display:block;
  border:1px solid #ddd;
  padding:0.5rem;
}
.review textarea.has-error{
  border:1px solid #ff6666;
}

.user-note{
  background-color: #e7e4f6;
  border-radius: 6px;
  padding: 16px 20px;
}

.user-note p{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: black;
}

.loading-review{
  min-height:462px;
}

.review .error{
  font-size: 13px;
  color: #ff6666;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.star-rating img{
  cursor: pointer;
}