.back-button{
  position:fixed;
  z-index: 10;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  padding:20px;
  box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.1);
  background-color:white;
}

.troubleshooting img{
  border:1px solid #d2d2d2;
}

.troubleshooting table img{
  border:none
}

.troubleshooting .title span{
  color:#999;
  font-size:14px;
}

.troubleshooting .title h1{
  font-size:22px;
  color:#4354FE;
  font-weight: 500;
  font-family: 'Poppins', sans-serif; 
}

.troubleshooting h2{
  font-size:16px;
  color:#4354FE;
  font-weight: 600;
  letter-spacing: 0.25px;
  font-family: 'Poppins', sans-serif; 
}

.troubleshooting .section{
  margin-bottom:30px;
}

.troubleshooting .section p,
.troubleshooting ol{
  font-family: 'Poppins', sans-serif; 
  font-size:13px;
  font-weight: 400;
  color:#333;
}

.troubleshooting ol{
  padding-left: 16px;
}

.troubleshooting ol li{
  position: relative;
  padding-left: 10px;
  margin-bottom: 20px;
}

.troubleshooting ol li::marker{
  color:#4354FE;
  font-weight: bold;
  display:block;
}

.troubleshooting img{
  width:100%;
  max-width: fit-content;
}

.troubleshooting table img{
  width:auto;
  max-width: inherit;
}

.troubleshooting  b{
  color:#4354FE;
}

.troubleshooting .separator{
  height: 100px;
  width:100%;
}

.troubleshooting table{
  border:1px solid #ccc;
  padding:4px;
}
.troubleshooting table th{
  background-color:#4354FE;
  color:white;
  padding:4px 10px;
  border:1px solid white;
}
.troubleshooting table small{
  font-size:13px;
}
.troubleshooting table td{
  border:1px solid #ccc;
  padding:4px 10px;
  font-size:13px;
  font-family: 'Poppins', sans-serif; 
}
.troubleshooting table .doble-border{
  border-bottom: 4px solid #ddd;
}

.troubleshooting table h3,
.troubleshooting table h2,
.troubleshooting table h4{
  font-size: 16px;
  font-weight: bold;
  display:block;
  padding:0px;
  margin:0px;
  padding-bottom: 4px;
  color:#4354FE;
}

.troubleshooting table p{
  margin-bottom: 0px;
}