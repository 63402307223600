/*
    Main class
*/
._newsdk_videocall
.player-container{
    background-color:#999;
    position:relative;
    min-height: 460px;
    overflow: hidden;
    border-radius:0px;
}

._newsdk_videocall
.btn-cerrar-teleconsulta{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 8px 30px;
    font-weight: bold;
}

._newsdk_videocall
.btn-cerrar-teleconsulta svg{
    margin-right:5px;
}
/*
    Usuario Local
*/
    ._newsdk_videocall
    .local-player-wrapper{
        position:absolute;
        right:0px;
        border-radius:5px;
        margin:10px;
        z-index: 2;
    }

    ._newsdk_videocall
    .local-player-wrapper
    .video-player{
        width: 150px;
        height: 112px;
        border: 1px solid white;
        background-color: #777;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        align-content: center;
    }

    ._newsdk_videocall
    .local-player-wrapper
    .video-player span{
        display:block;
        width:100%;
    }

    ._newsdk_videocall
    .btn-iniciar-teleconsulta{
        background-color: #4354FE;
        color: white;
        font-size: 13px;
        padding: 10px 20px;
        border-radius: 3px;
        font-weight: bold;
        letter-spacing: 0;
        border: 0px;
    }

    ._newsdk_videocall
    .btn-iniciar-teleconsulta:hover{
        background-color:black;
        color:white;
    }


/*
    Usuario remoto
*/
    .remote-player-wrapper{
        width: 100%;
        height: 460px;
        position: relative;
    }

    .remote-player-empty{
        display: flex;
        align-items: center;
        align-content: center;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
    }
    .remote-player-wrapper .video-player{
        display: flex;
        align-items: center;
        align-content: center;
    }
    .remote-player-wrapper .video-player .no-signal,
    .remote-player-empty span
    {
        font-size: 24px;
        color: white;
        display: block;
        width: 100%;
        text-align: center;
    }
    .remote-player-wrapper .video-player .no-signal svg{
        font-size:40px;
    }




.MuiTooltip-tooltip{
    font-size:13px !important;
}

/*
    Local Controllers
*/
.local-controllers{
    box-shadow: 0 2px 3px 0px rgb(0 0 0 / 10%);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 2px 10px;
}

.local-controllers .on{
    color:#2fde80;
}
.local-controllers .disabled{
    color:#999;
}
.local-controllers .off{
    color:red;
}


#alerts-teleconsulta{
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
}


/*
    Paciente Interfáz
*/
._newsdk_patient_interface{
  flex-grow: 1;
  display: flex;
}
.header-meet-patient{
  flex: none;
  margin: 0px !important;
  padding: 10px;
}
._newsdk_patient_interface .player-container{
  flex-grow: 1;
  min-height: initial;
}
._newsdk_patient_interface .local-controllers{
    height: 15vh;
    max-height: 70px;
}
._newsdk_patient_interface
.remote-player-wrapper{
    height:100%;
    width:auto;
}

.agora_video_player{
    object-fit: contain !important;
}