.popup .popup-container{
    position: relative;
}
.popup .popup-container .close-popup{
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: white;
    box-shadow: 0 0px 10px 0px rgb(0 0 0 / 40%);
    color: black;
    padding: 6px;
}
.popup .popup-container .close-popup:hover{
    background-color:black;
    color:white;
}

.controllers-ajustes:hover{
    border-radius: 10px
}
.controllers-ajustes span{
    font-size: 16px;
    font-weight: bold;
}
.controllers-ajustes svg{
    margin-right:3px;
}