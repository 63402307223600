/* 
   ================
   SinSalas
   ================
*/

.sin-salas{
    padding:40px;
}
   .sin-salas svg{
       font-size:100px;
       color:rgb(218, 218, 218);
   }

   .sin-salas h4{
       font-size: 26px;
       font-weight: bold;
       color:black;
   }
   .sin-salas p{
       font-size: 14px;
       color: #3d3d3d;
       opacity: 0.8;
       font-family: "Lato", "Arial", sans-serif;
   }


   .chat-guia-usuario p{
    color:black;
    opacity: 1;
    font-size:14px;
    letter-spacing: 0.5px;
}
.chat-guia-usuario strong{
    font-weight: bold;
}

.scroll{
    overflow-y:scroll;
}