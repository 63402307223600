._newsdk_medico_interface .status-paciente,
._newsdk_patient_interface .status-paciente{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #777;
}
._newsdk_medico_interface .status-styles,
._newsdk_patient_interface .status-styles{
    color:white;
}
._newsdk_medico_interface .status-styles:last-child,
._newsdk_patient_interface .status-styles:last-child{
    margin-bottom:10px;
}

.paciente-salir-teleconsulta p{
    color:white;
    margin-bottom:5px;
    margin-top:10px;
}
.paciente-salir-teleconsulta button{
    color:white;
    font-weight: bold;
    padding:8px 15px;
    background-color:white;
    color:black;
    border-radius: 3px;
    letter-spacing: 0px;
}