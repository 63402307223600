html, body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.calendar-screen{
  display: flex;
  flex-flow: column;
  height: 94vh;
  padding-top:20px;
  background-color: rgb(248, 246, 246);
}

.rbc-btn-group{
  border: none !important;
  background-color: #8480FF;
  color: white !important;
}

.rbc-btn-group>button:hover {
  background-color: black ;
  color:rgb(255, 255, 255);
}

.rbc-btn-group>button {
  color: white !important;
}

.rbc-btn-group>button::before {
  color: white !important;
  background-color: black !important;
}

.rbc-btn-group>button.rbc-active {
  color: rgb(255, 255, 255) !important;
  background-color:  var(--color-telesalud) ;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active{
  border-color: white !important;
  background-color:  var(--color-telesalud) !important;
}

.rbc-calendar{
  height: 100%;
  color: var(--color-telesalud);
  padding:0px 10px;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--color-telesalud) ;
}

h2.agenda{
  color: var(--color-telesalud);
  left:40%;
  top:30%;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  font-weight: bold;
}

/* Modal */
.ReactModalPortal > div{
    opacity: 0;
    overflow: auto !important;
    z-index: 9999999999;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}
.ReactModal__Overlay {
    margin: 0px !important;
}
.modal-fondo{
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}
.react-datetime-picker {
    display: block;
    position: relative;
}

.modal {
    background: rgb(253, 253, 253);
    border-radius: 5px;
    color: rgb(0, 0, 0) !important;
    display: inline;
    height:auto;
    width: 100vw;
    max-height: 600px;
    max-width: 768px;
    outline: none;
    font-size: 1.2rem;
    box-shadow: 0px 0px 2px 0px rgba(41, 41, 41, 0.5);
}

.w-40{
    width: 40% !important;
}
.w-45{
    width: 45% !important;
}

.link{
    cursor: pointer;
    background-color: var(--color-telesalud);
    color:white !important;
    padding: 7px;
    border-radius: 5px;
}

input:disabled {
    cursor: not-allowed;
    color:rgb(35, 40, 44);
}

.label-modal{
    font-size: 0.8rem;
    font-weight: bold;
    color: rgb(85, 85, 85);
}

/*
    Modal Agenda Medico
*/
.modal-agenda-medico h4{
    font-size:12px;
    font-family: 'Roboto', Arial, sans-serif;
    color:#999;
    font-weight:400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom:0;
}
.modal-agenda-medico h3{
    font-size:14px;
    font-family: 'Roboto', Arial, sans-serif;
    color:#999;
    font-weight:400;
}
.modal-agenda-medico h3 b{
    font-weight: 600;
    color:black;
}
.modal-agenda-medico h2{
    font-size:18px;
    font-family: 'Roboto', Arial, sans-serif;
    padding:0;
    margin:0;
}
.modal-agenda-medico .appointment-date h2{
  font-size:16px;
}
.modal-agenda-medico .appointment-date h2 svg{
  opacity: 0.2;
}
.modal-agenda-medico .patient-data{
    font-size:16px;
    font-family: 'Roboto', Arial, sans-serif;
    color:black;
    font-weight:400;
}
.modal-agenda-medico .label{
    font-size:14px;
    line-height: 14px;
    font-family: 'Roboto', Arial, sans-serif;
    color:#999;
    font-weight:400;
}


.rbc-toolbar-label{
  font-size:16px;
  text-transform: capitalize;
  font-weight: bold;
  display:block;
}

.rbc-btn-group{
  font-size:14px;
  border:0px;
  border-radius: 3px;
  overflow: hidden;
}
.rbc-toolbar .rbc-btn-group button{
  border:0px;
  font-weight: bold;
}
.rbc-toolbar .rbc-btn-group button:hover,
.rbc-toolbar .rbc-btn-group button:focus{
  background-color: rgb(84, 61, 208);
}



/* 
  Agenda Lateral
*/
.rbc-time-view{
  border-left:0px;
  border-right:0px;
}
.rbc-month-view .rbc-date-cell a{
  color:#999;
}
.rbc-month-view .rbc-date-cell a:hover{
  color: rgb(84, 61, 208);
}
.rbc-time-column .rbc-label{
  font-size:13px;
}
.rbc-time-view .rbc-time-header{
  display:none;
}
.agenda-lateral .rbc-event-content small,
.agenda-lateral .rbc-event-label{
  display:none;
}
.agenda-lateral .rbc-event-content strong{
  font-size:11px;
  font-weight: normal;
  letter-spacing: 0.2px;
  padding:4px;
  text-transform: capitalize;
}
.agenda-lateral .rbc-day-slot .rbc-event, 
.agenda-lateral .rbc-day-slot .rbc-background-event{
  height: auto !important;
  min-height: inherit !important;
  max-height: inherit !important;
}
.agenda-lateral{
  box-shadow: rgb(33 35 38 / 10%) 3px 0px 10px 0px;
  position: relative;
  z-index: 2;
}
.agenda-lateral .rbc-time-content{
  border-top:0px;
}
.rbc-today{
  background-color:white;
}

/* 
  Patient Ficha
*/
.ficha-patient-header{
  display:flex;
  padding-top: 10px;
}
.ficha-patient-header svg{
  font-size:50px;
  color:#ccc;
  padding-right: 10px;
}
.medicSite .ficha-patient-header h2{
  color:#4354FE;
  font-weight: bold;
  text-transform: capitalize;
  font-size:20px;
  margin:0px;
  padding:0px;
  line-height: 24px;
}
.medicSite .ficha-patient-header span{
  font-size:14px;
  color:#333
}

.ficha-patient-header-date{
  display:flex;
  justify-content:"flex-end";
}

.ficha-patient-header-date span{
  font-size:14px;
  color:#333;
  padding-right: 10px;
}
.ficha-patient-header-date svg{
  font-size:20px;
  color:#333;
}
.btn-iniciar-disabled{
  opacity: 0.8;
  background-color: #b1b1b1;
  color: white;
  font-size: 13px;
  padding: 10px 8px;
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: 0;
  pointer-events: "none"
}
.btn-iniciar-disabled img {
  margin-right: 10px;
  margin-top: -2px;
}
.tooltip-ficha {
  max-width: 100;
  text-align: center;
}

/*
  Subnavegación paciente
*/
.profesional-sub-nav{
  display:block;
  padding:0px;
  margin:0px;
  box-shadow: rgb(33 35 38 / 10%) 0px 6px 6px 0px;
  width:100%;
  border-top:1px solid #d2d2d2;
}
.profesional-sub-nav li{
  display:inline-block;
}
.profesional-sub-nav li button{
  font-size:14px;
  font-weight: bold;
  color:#4354FE;
  background-color:white;
  border:0px;
  padding:6px 8px;
  border-right:1px solid #dfdfdf;
  letter-spacing: 0.2px;
}
.profesional-sub-nav li:last-child button{
  border-right: 0px;
}
.profesional-sub-nav li button:hover,
.profesional-sub-nav li button.menu-active,
.btn-add:hover:hover{
  background-color:#4354FE;
  color:white;
}


/*
  Ficha Clinica
*/
.item-ficha-clinica{
  background-color:#f2f2f2;
  border-radius: 3px;
  border-bottom:1px solid #ccc;
}
.item-ficha-clinica button{
  width:100%;
  font-size:13px;
  text-align: left;
  padding:6px 10px;
}
.item-ficha-clinica button .activity-name{
  font-weight: bold;
  font-size:12px;
}
.item-ficha-clinica button .activity-descripcion{
  font-size:14px;
  letter-spacing: 0.2px;
}
.item-ficha-clinica-detalle .label{
  font-size:11px;
  text-transform: uppercase;
  color:#999;
  font-weight: bold;
  letter-spacing:1px;
}
.item-ficha-clinica-detalle h1{
  margin:0px;
  padding:0px;
  font-size:16px;
  color:#4354FE;
}
.item-ficha-clinica-detalle .info{
  font-size:13px;
  font-weight: normal;
  color:black;
}
.btn-add{
  width:100%;
  border:1px solid #ccc;
  box-shadow: rgb(33 35 38 / 10%) 0px 6px 6px 0px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size:14px;
  color:#4354FE;
  font-weight: bold;
}
.item-ficha-clinica-detalle pre{
  white-space: pre-wrap;
  font-size:14px;
  font-family: "Lato", "Arial", sans-serif;
  line-height:24px;
  color:#333
}
.scrollbar{
  height: 400px;
  overflow-y: scroll;
  padding-right: 12px;
  margin-bottom:20px;
}
body.vista-profesional{
  overflow: inherit !important;
}
.scrollbar::-webkit-scrollbar-track,
.vista-profesional::-webkit-scrollbar-track {
  background-color: #e2e2e2;
  border-radius: 4px;
}
.vista-profesional::-webkit-scrollbar{
  width: 10px;
  background-color: #f5f5f5;
  display: block;
}
.scrollbar::-webkit-scrollbar{
  width: 10px;
  background-color: #f5f5f5;
  display: block;
}
.vista-profesional .scrollbar::-webkit-scrollbar{
  width: 8px;
  background-color: #f5f5f5;
  display: block;
}
.scrollbar::-webkit-scrollbar-thumb,
.vista-profesional::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #bbb;
  margin-right: 2px;
}



/*
  Base container
*/
.ficha-paciente-container{
  background-color: white;
  /* border-radius: 3px; */
  box-shadow: rgb(33 35 38 / 10%) 2px 2px 5px 0px;
}
#wrapper {
  overflow-x: hidden;
}
.vista-profesional .chatPaciente .chatGlobe{
  bottom:28px;
}
#sidebar-wrapper {
  width:300px;
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
  padding:20px;
  background-color:#f2f2f2;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

@media (max-width: 767px) {
  #sidebar-wrapper{
    margin-left: -10.8rem;
  }
}