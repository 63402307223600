.video-player{
    background-color:#333;
    width:100%;
    height:100%;
}

.remote-status{
    display:block;
    border-radius: 15px;
    font-size:12px;
    font-weight:bold;
    background-color:#333;
    width:30px;
    height:30px;
    line-height: 30px;
    text-align: center;
    margin:5px;
}

.no-live{
    background-color:red;
    color:white;
}

.live{
    background-color:#2fde80;
    color:white;
}

.remote-aside-status{
    position:absolute;
    top:5px;
    left:4px
}

.audio-user{
     background-color: transparent;
     color: #ece911 !important;
     padding: 5px;
}
