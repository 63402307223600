.page-404{
  font-family: 'Poppins', sans-serif; 
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;

  background-image: url(./images/not-found-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.page-404-title{
  font-size:8rem;
  line-height: 100%;
  font-weight: 500;
  display:block;
  color:#4354FE;
}

.page-404-subtitle{
  display:block;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 120%;
  color:#696A84;
  margin-bottom: 2rem;
}

.page-404-subtitle b{
  color:#4354FE;
  font-weight: bold;
}

.page-404 p{
  color:#696A84;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif; 
}